import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FooterCard } from "../components/FooterCard";
import axios from "axios";
import { base_url } from "../services/const";
import CompanyProfileDescriptionModal from "../components/CompanyProfileModals/CompanyProfileDescriptionModal";
import CompanyProfileDetailsModal from "../components/CompanyProfileModals/CompanyProfileDetailsModal";
import HeroImageModal from "../components/CompanyProfileModals/HeroImageModal";
import { NotificationManager } from "react-notifications";
import OfferingShowcase from "../components/CompanyProfileComponents/OfferingShowcase";
import SearchingShowcase from "../components/CompanyProfileComponents/SearchingShowcase";
import PnSShowCase from "../components/CompanyProfileComponents/PnSShowCase";
import {
  faPen,
  faMobileAlt,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { SearchSpinner, userLevelColors } from "../services/globalFunctions";
import { activeCompany } from "../store/actions/breadcrumbsAction";
import { Link } from "react-router-dom";

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum interdum dignissim erat nec venenatis. Sed aliquam mauris eu ultricies sollicitudin. Proin a erat sed sem viverra venenatis eget vel tortor. Nunc venenatis molestie arcu vel semper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse potenti. Sed eget erat eros. Pellentesque ornare eros eget gravida convallis. Morbi ut est eros. Donec id dignissim diam. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean posuere, felis nec gravida vulputate, elit risus convallis ligula, ut dignissim nisl ante nec libero. Pellentesque in eleifend turpis. In imperdiet ante dolor, sit amet rutrum dolor elementum cursus.",
      companyId: this.props.match.params.companyId,
      companyLongDescription: "",
      updatedDescription: "",
      companyMobile: "",
      updatedMobile: "",
      companyPhone: "",
      updatedPhone: "",
      companyFax: "",
      updatedFax: "",
      companyContactDescription: "",
      updatedContactDescription: "",
      mainContactGender: "",
      updatedContactGender: "",
      mainContactTitle: "",
      updatedContactTitle: "",
      companyContactLastName: "",
      updateContactLastName: "",
      companyContactFirstName: "",
      updateContactFirstName: "",
      companyUserId: "",
      companyName: "",
      updatedCompanyName: "",
      companyHeroImage: "",
      updatedHeroImage: "",
      companyLogo: "",
      updatedLogo: "",
      companyServices: "",
      companyHqCity: "",
      updatedHqCity: "",
      companyHqStreet: "",
      updatedHqStreet: "",
      companyHqCountry: "",
      updatedHqCountry: "",
      companyHqPostCode: "",
      updatedPostCode: "",
      updatedContactImage: "",
      contactImage: "",
      showDescriptionModal: false,
      showDetailModal: false,
      showHeroImageModal: false,
      showServicesModal: false,
      editProfile: false,
      showEditBtn: false,
      contactEmail: "",
      updatedContactEmail: "",
      pns: [],
      agContact: {},
      showLoading: false,
      companyUserLevel: "",
    };
    this.getCompanyDetails = this.getCompanyDetails.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.isActiveUserProfile = this.isActiveUserProfile.bind(this);
    this.getPnS = this.getPnS.bind(this);
    this.returnPlaceHolderImg = this.returnPlaceHolderImg.bind(this);
    this.onClickMap = this.onClickMap.bind(this);
  }

  componentDidMount() {
    this.getCompanyDetails();
    this.isActiveUserProfile();
    this.getPnS();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.match.params.companyId !== prevProps.match.params.companyId
    ) {
      this.setState({ companyId: this.props.match.params.companyId }, () => {
        this.getCompanyDetails();
        this.isActiveUserProfile();
        this.getPnS();
      });
    }
    if (
      this.props.firstLogin !== prevProps.firstLogin &&
      this.props.firstLogin
    ) {
      this.setState({ welcomeModal: true });
    }
  }

  getCompanyDetails() {
    this.setState({ showLoading: true });
    axios
      .request({
        method: "GET",
        url: `${base_url}/company/${this.state.companyId}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          this.props.activeCompany(res.data.data.company_name);
          this.setState({
            companyData: res.data.data,
            companyLongDescription: res.data.data.company_long_description
              ? res.data.data.company_long_description
              : "Enter your Company description here.",
            updatedDescription: res.data.data.company_long_description,
            companyPhone: res.data.data.company_main_contact_phone,
            updatedPhone: res.data.data.company_main_contact_phone,
            companyMobile: res.data.data.company_main_contact_mobile,
            updatedMobile: res.data.data.company_main_contact_mobile,
            companyFax: res.data.data.company_main_contact_fax,
            updatedFax: res.data.data.company_main_contact_fax,
            companyContactLastName: res.data.data.company_main_contact_lname,
            updateContactLastName: res.data.data.company_main_contact_lname,
            companyContactJobTitle: res.data.data.company_main_contact_jobtitle,
            updatedContactJobTitle: res.data.data.company_main_contact_jobtitle,
            companyContactDescription:
              res.data.data.company_main_contact_description,
            updatedContactDescription:
              res.data.data.company_main_contact_description,
            contactEmail: res.data.data.company_main_contact_email,
            updatedContactEmail: res.data.data.company_main_contact_email,
            mainContactGender: res.data.data.company_main_contact_gender_title,
            updatedContactGender:
              res.data.data.company_main_contact_gender_title,
            mainContactTitle: res.data.data.company_main_contact_title,
            updatedContactTitle: res.data.data.company_main_contact_title,
            companyContactFirstName: res.data.data.company_main_contact_fname,
            updateContactFirstName: res.data.data.company_main_contact_fname,
            companyUserId: res.data.data.user_id,
            companyName: res.data.data.company_name,
            updatedCompanyName: res.data.data.company_name,
            updatedLogo: res.data.data.company_logo,
            companyLogo: res.data.data.company_logo,
            companyHeroImage: res.data.data.company_hero_image,
            updatedHeroImage: res.data.data.company_hero_image,
            companyServices: res.data.data.company_services,
            companyHqCity: res.data.data.company_hq_city,
            updatedHqCity: res.data.data.company_hq_city,
            companyHqStreet: res.data.data.company_hq_street,
            updatedHqStreet: res.data.data.company_hq_street,
            companyHqCountry: res.data.data.company_hq_country,
            updatedHqCountry: res.data.data.company_hq_country,
            companyHqPostCode: res.data.data.company_hq_postcode,
            updatedPostCode: res.data.data.company_hq_postcode,
            contactImage: res.data.data.company_main_contact_image,
            agContact: res.data.data.ag_contact,
            showLoading: false,
            companyUserLevel: res.data.data.user_level,
          });
        } else {
          this.setState({ showLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ showLoading: false });
        console.log(err);
      });
  }

  getPnS() {
    axios
      .request({
        method: "get",
        url: `${base_url}/company/pns/${this.state.companyId}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        if (ret.status === 200 && ret.data.data) {
          this.setState({ pns: ret.data.data });
        } else {
          this.setState({ pns: [] });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  isActiveUserProfile() {
    this.props.activeUserCompanyArr.map((id) => {
      if (id === parseInt(this.state.companyId)) {
        this.setState({ showEditBtn: true });
      }
      return true;
    });
  }

  updateProfile() {
    let data = new URLSearchParams();
    data.append("long_description", this.state.companyLongDescription);
    data.append("main_contact_phone", this.state.companyPhone);
    data.append("main_contact_mobile", this.state.companyMobile);
    data.append("main_contact_fax", this.state.companyFax);
    data.append("main_contact_lname", this.state.companyContactLastName);
    data.append("main_contact_fname", this.state.companyContactFirstName);
    data.append("main_contact_jobtitle", this.state.companyContactJobTitle);
    data.append("main_contact_email", this.state.contactEmail);
    data.append("main_contact_gender_title", this.state.mainContactGender);
    data.append("main_contact_title", this.state.mainContactTitle);
    data.append("name", this.state.companyName);
    data.append("hq_city", this.state.companyHqCity);
    data.append("hq_street", this.state.companyHqStreet);
    data.append("hq_country", this.state.companyHqCountry);
    data.append("hq_postcode", this.state.companyHqPostCode);
    typeof this.state.updatedHeroImage === "string" &&
    this.state.updatedHeroImage.length > 0
      ? data.append("company_hero_image_id", this.state.updatedHeroImage)
      : void 0;
    typeof this.state.updatedLogo === "string" &&
    this.state.updatedLogo.length > 0
      ? data.append("company_logo_image_id", this.state.updatedLogo)
      : void 0;
    typeof this.state.updatedContactImage === "string" &&
    this.state.updatedContactImage.length > 0
      ? data.append("company_contact_image_id", this.state.updatedContactImage)
      : void 0;
    axios
      .request({
        method: "post",
        url: `${base_url}/company/${this.state.companyId}`,
        data: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.getCompanyDetails();
          this.setState({ showHeroImageModal: false });
          NotificationManager.success("Profile Updated", "Success");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  returnPlaceHolderImg() {
    switch (this.state.mainContactGender) {
      case "Male":
        return "MalePlaceholder.jpg";
      case "Female":
        return "FemalePlaceholder.jpg";
      default:
        return "MalePlaceholder.jpg";
    }
  }

  onClickMap() {
    let address =
      this.state.companyHqStreet +
      " " +
      this.state.companyHqPostCode +
      " " +
      this.state.companyHqCity +
      " " +
      this.state.companyHqCountry;
    window.open(`https://www.google.com/maps/search/?api=1&query=${address}`);
  }

  render() {
    let navIcon = userLevelColors(this.state.companyUserLevel).icon;
    return (
      <div className="CompanyProfile page-container">
        <div className="position-relative page-header">
          <img
            className={
              "page-header " + (this.state.companyHeroImage ? "" : "d-none")
            }
            style={{
              backgroundImage: this.state.companyHeroImage,
              objectFit: "cover",
            }}
            src={
              this.state.companyHeroImage
                ? this.state.companyHeroImage.data
                  ? `data:image/jpeg;base64,${this.state.companyHeroImage.data}`
                  : require(`../assets/images/static-ad.jpg`)
                : require(`../assets/images/static-ad.jpg`)
            }
            alt="..."
          />
          <span
            className={
              "position-absolute upload-hero-image-btn px-3 py-2 " +
              (this.state.editProfile ? "" : "d-none")
            }
            onClick={() => this.setState({ showHeroImageModal: true })}
          >
            <FontAwesomeIcon icon={faUpload} size={"2x"} />
          </span>
        </div>
        <nav
          className={
            "navbar navbar-light " + (this.state.showLoading ? "d-none" : "")
          }
          style={{
            background: userLevelColors(this.state.companyUserLevel).bg,
          }}
        >
          <div className="container">
            <div className="row w-100" style={{ alignItems: "center" }}>
              <div className="col-lg-8">
                <input
                  type="button"
                  style={{ padding: "3px 10px", fontSize: "14px" }}
                  className={
                    "btn btn-small btn-dark " +
                    (this.state.showEditBtn ? "" : "d-none")
                  }
                  value={
                    this.state.editProfile ? "finish editing" : "Edit Profile"
                  }
                  onClick={() =>
                    this.setState((prevState) => ({
                      editProfile: !prevState.editProfile,
                    }))
                  }
                />
                {/*<input type="button" className="btn btn-transparent ml-4 d-none" value="More..."/>*/}
              </div>
              <div className="col-lg-4" style={{ paddingRight: "0" }}>
                <div
                  className="text-uppercase text-white font-weight-light w-100 text-left pl-3"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    fontSize: "18px",
                  }}
                >
                  <img
                    className="pr-4"
                    style={{ height: "40px", left: "140px", top: "5px" }}
                    src={require(`../assets/userIcons/${navIcon}`)}
                    alt={"..."}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      color: userLevelColors(this.state.companyUserLevel).font,
                    }}
                  >
                    {userLevelColors(this.state.companyUserLevel).label}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <SearchSpinner
          showLoading={this.state.showLoading}
          level={this.props.group}
        />
        <div
          className={"container " + (this.state.showLoading ? "d-none" : "")}
          style={{ marginTop: 90 }}
        >
          <div className="row">
            <div className="col-lg-8 company-description">
              <div className="company-description-box box">
                <div
                  className="company-description-content"
                  style={{ fontSize: "14px" }}
                >
                  {this.state.editProfile ? (
                    <button
                      className="btn"
                      onClick={() =>
                        this.setState({ showDescriptionModal: true })
                      }
                      style={{ padding: "0", marginBottom: "10px" }}
                    >
                      <FontAwesomeIcon icon={faPen} /> Edit Description
                    </button>
                  ) : (
                    ""
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.companyLongDescription,
                    }}
                  />
                </div>
              </div>
              <div>
                <FooterCard />
              </div>
              {/*Offering*/}
              <div>
                <OfferingShowcase
                  offerings={this.state.pns}
                  editProfile={this.state.editProfile}
                  companyId={this.state.companyId}
                  getPnS={this.getPnS}
                  agContact={this.state.agContact}
                />
              </div>
              {/*Offering Ends*/}
              {/*Searching*/}
              <div>
                <SearchingShowcase
                  searches={this.state.pns}
                  editProfile={this.state.editProfile}
                  companyId={this.state.companyId}
                  getPnS={this.getPnS}
                  agContact={this.state.agContact}
                />
              </div>
              {/*Searching ends*/}
              {/*PNS*/}
              <div>
                <PnSShowCase
                  company_logo={this.state.companyLogo}
                  pns={this.state.pns}
                  editProfile={this.state.editProfile}
                  companyId={this.state.companyId}
                  getPnS={this.getPnS}
                  agContact={this.state.agContact}
                />
              </div>
              {/*PNS ends*/}
            </div>
            <div className="col-lg-4 company-profile">
              <div className="company-profile-image-box box">
                <div className="company-image-box">
                  <img
                    src={
                      this.state.companyLogo
                        ? this.state.companyLogo.data
                          ? `data:image/jpeg;base64,${this.state.companyLogo.data}`
                          : require("../assets/placeholders/comp-logo-placeholder.png")
                        : require("../assets/placeholders/comp-logo-placeholder.png")
                    }
                    className="company-profile-image"
                    alt="..."
                  />
                </div>
              </div>
              {/*<div className="company-profile-box box">*/}
              {/*  <div className="company-credentials">*/}
              {/*    {this.state.editProfile ? (*/}
              {/*      <button*/}
              {/*        className="btn"*/}
              {/*        onClick={() => this.setState({ showDetailModal: true })}*/}
              {/*        style={{ padding: "0", marginBottom: "10px" }}*/}
              {/*      >*/}
              {/*        <FontAwesomeIcon icon={faPen} /> Edit Profile*/}
              {/*      </button>*/}
              {/*    ) : (*/}
              {/*      ""*/}
              {/*    )}*/}
                  {/*<h3 className="company-profile-title">*/}
                  {/*  {this.state.companyName}*/}
                  {/*</h3>*/}
                  {/*<p className="company-profile-location">*/}
                  {/*  {this.state.companyHqCity}, {this.state.companyHqCountry}*/}
                  {/*</p>*/}
                  {/*<hr className="seperator" />*/}
                  {/*<p className="subsection text-uppercase">Headquarter</p>*/}
                  {/*<div onClick={this.onClickMap}>*/}
                  {/*  <p className="company-address-list">*/}
                  {/*    {this.state.companyHqStreet},{" "}*/}
                  {/*    {this.state.companyHqPostCode}*/}
                  {/*  </p>*/}
                  {/*  <p className="company-address-list">*/}
                  {/*    {this.state.companyHqCity}, {this.state.companyHqCountry}*/}
                  {/*  </p>*/}
                  {/*</div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {}
              <div
                className="company-profile-box box"
                style={{ paddingTop: "15px" }}
              >
                <div className="company-contact">
                  <p className="subsection text-uppercase">Main contact</p>
                  <div className="row">
                    <div className="col-lg-6">
                      <img
                        style={{
                          marginRight: "20px",
                          width: "auto",
                          height: "150px",
                          objectFit: "contain",
                        }}
                        src={
                          this.props.match.params.companyId.includes("test")
                            ? this.state.contactImage.length > 0
                              ? require(`../assets/placeholders/${this.state.contactImage}`)
                              : ""
                            : this.state.contactImage
                            ? this.state.contactImage.data
                              ? `data:image/jpeg;base64,${this.state.contactImage.data}`
                              : require(`../assets/placeholders/${this.returnPlaceHolderImg()}`)
                            : ""
                        }
                        alt="..."
                      />
                    </div>
                    <div className="company-contact-list col-lg-6">
                      <p className="company-contact-list-name">
                        <span
                          className={
                            this.state.mainContactTitle ? "" : "d-none"
                          }
                        >
                          {this.state.mainContactTitle !== 'null' ? this.state.mainContactTitle : ''}
                        </span>
                        {this.state.companyContactFirstName}{" "}
                        {this.state.companyContactLastName}
                      </p>
                      <p
                        className={
                          "font-weight-light " +
                          (this.state.companyContactJobTitle ? "" : "d-none")
                        }
                      >
                        {this.state.companyContactJobTitle}
                      </p>
                      <p
                        className={
                          "company-contact-list-phone " +
                          (this.state.companyPhone ? "" : "d-none")
                        }
                        style={{ fontSize: "14px" }}
                      >
                        <FontAwesomeIcon icon={faPhone} className="mr-1" />{" "}
                        {this.state.companyPhone}
                      </p>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          (window.location.href = `skype:${this.state.companyMobile}?call`)
                        }
                      >
                        <p
                          className={
                            "company-contact-list-phone " +
                            (this.state.companyMobile ? "" : "d-none")
                          }
                          style={{ fontSize: "14px" }}
                        >
                          <FontAwesomeIcon
                            icon={faMobileAlt}
                            style={{ fontSize: "16px" }}
                            className="mr-2"
                          />{" "}
                          {this.state.companyMobile}
                        </p>
                      </a>
                      {/*<p className="company-contact-list-mobile">M. {this.state.companyMobile}</p>*/}
                      {/*<p className="company-contact-list-fax">F. {this.state.companyFax}</p>*/}
                      <a
                        onClick={() =>
                          (window.location.href = `mailto:${this.state.contactEmail}`)
                        }
                      >
                        <p
                          className={
                            "company-contact-list-email d-inline-flex " +
                            (this.state.contactEmail ? "" : "d-none")
                          }
                          style={{ fontSize: "14px" }}
                        >
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="mr-2 mt-1"
                          />
                          <span style={{ wordBreak: "break-word" }}>
                            {this.state.contactEmail}
                          </span>
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "company-profile-box box " +
                  (Array.isArray(this.state.agContact) ? "d-none" : "")
                }
                style={{ paddingTop: "15px" }}
              >
                <div className={"agent-incharge mt "}>
                  <p className="subsection text-uppercase">Agent In Charge</p>
                  <Link
                    to={`/company-profile/${this.state.agContact.company_id}`}
                  >
                    <img
                      style={{
                        width: "100%",
                        maxHeight: "150px",
                        objectFit: "contain",
                      }}
                      alt={"..."}
                      src={
                        !Array.isArray(this.state.agContact.logo)
                          ? this.state.agContact.logo
                            ? `data:image/jpeg;base64,${this.state.agContact.logo.data}`
                            : "https://via.placeholder.com/350x150"
                          : "https://via.placeholder.com/350x150"
                      }
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CompanyProfileDescriptionModal
          description={this.state.companyLongDescription}
          showModal={this.state.showDescriptionModal}
          onHide={() => this.setState({ showDescriptionModal: false })}
          onChange={(e) => this.setState({ updatedDescription: e })}
          onSubmit={() =>
            this.setState(
              {
                companyLongDescription: this.state.updatedDescription,
                showDescriptionModal: false,
              },
              () => this.updateProfile()
            )
          }
        />
        <CompanyProfileDetailsModal
          showModal={this.state.showDetailModal}
          onHide={() => this.setState({ showDetailModal: false })}
          companyData={this.state.companyData}
          onChangeCompanyName={(e) =>
            this.setState({ updatedCompanyName: e.target.value })
          }
          onChangeFirstName={(e) =>
            this.setState({ updateContactFirstName: e.target.value })
          }
          onChangeLastName={(e) =>
            this.setState({ updateContactLastName: e.target.value })
          }
          onChangePhoneNo={(e) =>
            this.setState({ updatedPhone: e.target.value })
          }
          onChangeMobileNo={(e) =>
            this.setState({ updatedMobile: e.target.value })
          }
          onChangeFaxNo={(e) => this.setState({ updatedFax: e.target.value })}
          onChangeCompanyLogo={(e) => this.setState({ updatedLogo: e })}
          onChangeCompanyContactPic={(e) =>
            this.setState({ updatedContactImage: e })
          }
          onChangeCountry={(e) =>
            this.setState({ updatedHqCountry: e.target.value })
          }
          onChangeCity={(e) => this.setState({ updatedHqCity: e.target.value })}
          onChangePostCode={(e) =>
            this.setState({ updatedPostCode: e.target.value })
          }
          onChangeStreet={(e) =>
            this.setState({ updatedHqStreet: e.target.value })
          }
          onChangeContactJobTitle={(e) =>
            this.setState({ updatedContactJobTitle: e.target.value })
          }
          onChangeContactDescription={(e) =>
            this.setState({ updatedContactDescription: e.target.value })
          }
          onChangeContactEmail={(e) =>
            this.setState({ updatedContactEmail: e.target.value })
          }
          onChangeContactGender={(e) =>
            this.setState({ updatedContactGender: e.target.value })
          }
          onChangeContactTitle={(e) =>
            this.setState({ updatedContactTitle: e.target.value })
          }
          onSubmit={() => {
            this.setState(
              {
                companyName: this.state.updatedCompanyName,
                companyContactLastName: this.state.updateContactLastName,
                companyContactFirstName: this.state.updateContactFirstName,
                companyPhone: this.state.updatedPhone,
                companyMobile: this.state.updatedMobile,
                companyFax: this.state.updatedFax,
                companyHqCity: this.state.updatedHqCity,
                companyHqCountry: this.state.updatedHqCountry,
                companyHqStreet: this.state.updatedHqStreet,
                companyHqPostCode: this.state.updatedPostCode,
                companyContactJobTitle: this.state.updatedContactJobTitle,
                companyContactDescription: this.state.updatedContactDescription,
                contactEmail: this.state.updatedContactEmail,
                mainContactTitle: this.state.updatedContactTitle,
                mainContactGender: this.state.updatedContactGender,
                showDetailModal: false,
              },
              () => this.updateProfile()
            );
          }}
        />
        <HeroImageModal
          show={this.state.showHeroImageModal}
          onHide={() => this.setState({ showHeroImageModal: false })}
          onSubmit={(image_id) =>
            this.setState({ updatedHeroImage: image_id }, () =>
              this.updateProfile()
            )
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let activeUserCompanyArr = state.login.company_ids;
  let level = state.login.group;
  let group = state.login.level;
  let firstLogin = state.login.first_login;
  return {
    token,
    activeUserCompanyArr,
    level,
    firstLogin,
    group,
  };
};

const mapDispatchToProps = (dispatch) => ({
  activeCompany: (data) => dispatch(activeCompany(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
