import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faImage } from "@fortawesome/free-solid-svg-icons";
import {
  toBase64,
  uploadImage,
  userLevelColors,
} from "../../services/globalFunctions";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

class HeroImageModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heroImage: null,
      showFileError: false,
      image: null,
      heroThumbnail: null,
    };
    this.handleProfilePictureUpload =
      this.handleProfilePictureUpload.bind(this);
    this.onSubmitHeroImg = this.onSubmitHeroImg.bind(this);
  }

  async handleProfilePictureUpload(e) {
    console.log();
    if ((e.target.files[0].size / 1024 / 1024).toFixed(4) > 5) {
      NotificationManager.error("File size too large. Max size 5mb.", "Error");
    } else {
      this.setState({ image: e.target.files[0] });
      let logo = {
        mime: e.target.files[0].type,
        filename: e.target.files[0].name,
      };
      await toBase64(e.target.files[0]).then((res) => {
        logo.data = res;
        this.setState({ heroImage: logo, showFileError: false });
        // this.props.onChangeHeroImage(logo);
      });
    }
  }

  async onSubmitHeroImg() {
    let formData = new FormData();
    formData.append("image", this.state.image);
    let image_id = await uploadImage(formData, this.props.token);
    this.props.onSubmit(image_id);
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        size={"lg"}
        onHide={this.props.onHide}
        className="HeroImageModal"
      >
        <Modal.Header
          closeButton
          style={{ background: userLevelColors(this.props.level).bg }}
        >
          <h3 style={{ color: userLevelColors(this.props.level).font }}>
            Cover picture
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="file-upload-container">
            <div className="file-upload-content">
              <div className={this.state.heroImage ? "d-none" : ""}>
                {this.props.heroImg ? (
                  this.props.heroImg.data ? (
                    <img
                      style={{ height: "65px" }}
                      src={`data:image/jpeg;base64,${this.props.heroImg.data}`}
                      alt={"..."}
                    />
                  ) : (
                    <FontAwesomeIcon size={"3x"} icon={faImage} />
                  )
                ) : (
                  <FontAwesomeIcon size={"3x"} icon={faImage} />
                )}
              </div>
              <div className={this.state.heroImage ? "" : "d-none"}>
                {this.state.heroImage ? (
                  <img
                    style={{ height: "65px" }}
                    src={`${this.state.heroImage.data}`}
                    alt={"..."}
                  />
                ) : (
                  <FontAwesomeIcon size={"3x"} icon={faImage} />
                )}
              </div>
              <h4 className="title pt-3 mb-1">Drag & Drop</h4>
              <h6 className="sub-title mb-3">
                Your company hero image goes here.
              </h6>
              <br />
              <small className={"file-formats mt-3 mb-0 "}>
                {this.state.heroImage
                  ? this.state.heroImage.filename
                  : "(.PNG or .JPG)"}
              </small>
              {!this.state.heroImage && (
                <p className="sub-title mt-1">max 5mb</p>
              )}
              &nbsp;
              <small
                className={
                  "text-danger " + (this.state.showFileError ? "" : "d-none")
                }
              >
                Select a file to import
              </small>
            </div>
            <input
              type="file"
              id="file-upload"
              className={
                "choose-file-input " +
                (this.state.heroImage ? "" : "file-selected")
              }
              onChange={this.handleProfilePictureUpload}
              accept=".png, .jpeg, .jpg"
            />
            <div className="choose-file-btn">
              <button className="btn inverse-btn-secondary">Choose File</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.props.onHide}
            style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
          >
            Close
          </Button>
          <Button
            variant="dark"
            style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
            onClick={this.onSubmitHeroImg}
          >
            Save Image
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HeroImageModal);
