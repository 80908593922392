import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import {base_url} from "../services/const";
import {NotificationManager} from "react-notifications";
import {JsEncrypt, uploadImage, userLevelColors} from "../services/globalFunctions";

class UserSettingsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profilePicture: "",
            fname: "",
            lname: "",
            email: "",
            newEmail: "",
            nick: "",
            companyName: "",
            jobtitle: "",
            gender: "",
            title: "",
            password: "",
            newPassword: "",
            repeatPassword: "",
            showPwError: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.getUserData = this.getUserData.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.show !== prevProps.show && this.props.show) {
            this.getUserData();
        }
    }

    getUserData() {
        axios.request({
            method: 'GET',
            url: `${base_url}/user/myprofile`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then(ret => {
            if(ret.data.status === 200) {
                this.setState({
                    email: ret.data.data.email,
                    fname: ret.data.data.fname,
                    gender: ret.data.data.gender_title,
                    jobtitle: ret.data.data.jobtitle,
                    lname: ret.data.data.lname,
                    companyName: ret.data.data.name,
                    phone: ret.data.data.phone,
                    title: ret.data.data.title,
                    nick: ret.data.data.nick,
                    profilePicture: ""
                })
            }
        })
    }

    async handleFileUpload(e) {
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append("image", file);
        let image_id = await uploadImage(formData, this.props.token);
        this.setState({profilePicture: image_id, showFileError: false});
    }

    onSubmit() {
        if(!this.state.showPwError) {
            let data = new URLSearchParams();
            let enc = new JsEncrypt();
            this.state.email ? data.append("email_old", this.state.email) : void 0;
            this.state.newEmail ? data.append('email', this.state.newEmail) : void 0;
            this.state.fname ? data.append("fname", this.state.fname) : void 0;
            this.state.gender ? data.append("gender_title", this.state.gender) : void 0;
            this.state.lname ? data.append("lname", this.state.lname) : void 0;
            this.state.title ? data.append("title", this.state.title) : void 0;
            this.state.password ? data.append('password_old', enc.encode(this.state.password)) : void 0;
            this.state.newPassword ? data.append('password', enc.encode(this.state.newPassword)) : void 0;
            this.state.newPassword ? data.append('password_repeat', enc.encode(this.state.repeatPassword)) : void 0;
            axios.request({
                method: "post",
                data: data,
                url: `${base_url}/user/myprofile`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    'Authorization': `Bearer ${this.props.token}`
                },
            })
                .then((ret) => {
                    if(ret.data.status === 200 || ret.data.status === 204) {
                        NotificationManager.success('Profile Updated', 'Success');
                        this.getUserData();
                        this.props.onHide();
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }

    }

    onChangePassword() {
        if(this.state.newPassword !== this.state.repeatPassword) {
            this.setState({showPwError: true});
        } else {
            this.setState({showPwError: false});
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                size={"lg"}
                onHide={this.props.onHide}
                className="company-profile-modal"
            >
                <Modal.Header className="border-0" style={{background: userLevelColors(this.props.level).bg}} closeButton>
                    <h3 className="">
                        User settings
                    </h3>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <form>
                        <div className="row mt-3">
                            <div className="form-group col-lg-2">
                                <select
                                    className={"form-control  " + (userLevelColors(this.props.level).className)}
                                    onChange={(e)=>this.setState({gender: e.target.value})}
                                    defaultValue={this.state.gender}
                                >
                                    <option disabled value={null}>Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-2">
                                <select
                                    className={"form-control  " + (userLevelColors(this.props.level).className)}
                                    onChange={(e)=>this.setState({title: e.target.value})}
                                    defaultValue={this.state.title}
                                >
                                    <option disabled value={null}>Title</option>
                                    <option value="Dr.">Dr.</option>
                                    <option value="Prof.">Prof.</option>
                                    <option value="Mr.">Mr.</option>
                                    <option value="Ms.">Ms.</option>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-4">
                                <input
                                    onChange={(e)=>this.setState({fname: e.target.value})}
                                    defaultValue={this.state.fname}
                                    type="text"
                                    className={"form-control  " + (userLevelColors(this.props.level).className)}
                                    placeholder="First Name" />
                            </div>
                            <div className="form-group col-lg-4">
                                <input
                                    onChange={(e)=>this.setState({lname: e.target.value})}
                                    defaultValue={this.state.lname}
                                    type="text"
                                    className={"form-control  " + (userLevelColors(this.props.level).className)}
                                    placeholder="Last Name"
                                />
                            </div>
                            <div className="form-group col-lg-6">
                                <input
                                    onChange={(e)=>this.setState({email: e.target.value})}
                                    defaultValue={this.state.email}
                                    type="email"
                                    className={"form-control  " + (userLevelColors(this.props.level).className)}
                                    placeholder="Old email"
                                    autoComplete="old-password"
                                />
                            </div>
                            <div className="form-group col-lg-6">
                                <input
                                    onChange={(e)=>this.setState({newEmail: e.target.value})}
                                    defaultValue={this.state.newEmail}
                                    type="email"
                                    className={"form-control  " + (userLevelColors(this.props.level).className)}
                                    placeholder="New email"
                                    autoComplete="new-email"
                                />
                            </div>
                            <div className="form-group col-lg-12">
                                <input
                                    onChange={(e)=>this.setState({password: e.target.value})}
                                    defaultValue={this.state.password}
                                    type="password"
                                    className={"form-control  " + (userLevelColors(this.props.level).className)}
                                    placeholder="Old password"
                                    autoComplete="old-password"
                                />
                            </div>
                            <div className="form-group col-lg-6">
                                <input
                                    onChange={(e)=>this.setState({newPassword: e.target.value}, () => this.onChangePassword())}
                                    defaultValue={this.state.newPassword}
                                    type="password"
                                    className={"form-control  " + (userLevelColors(this.props.level).className)}
                                    placeholder="New password"
                                    autoComplete="new-password"
                                />
                            </div>
                            <div className="form-group col-lg-6">
                                <input
                                    onChange={(e)=>this.setState({repeatPassword: e.target.value}, () => this.onChangePassword())}
                                    defaultValue={this.state.repeatPassword}
                                    type="password"
                                    className={"form-control  " + (userLevelColors(this.props.level).className)}
                                    placeholder="Repeat password"
                                    autoComplete="repeat-password"
                                />
                                <small className={"text-danger " + (this.state.showPwError ? "" : "d-none")}><i>Password do not match</i></small>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}}
                        variant="secondary"
                        onClick={this.props.onHide}>
                        Close
                    </Button>
                    <Button
                        style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}}
                        variant="dark"
                        onClick={this.onSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    let userRole = state.login.group;
    let token = state.login.token;
    let level = state.login.level;
    return {
        userRole, token, level
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UserSettingsModal)
);
