import React from "react";
import {
  Router,
  Route,
  withRouter,
  Switch,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import Login from "../layouts/Login";
import Navbar from "../components/Navbar";
import Newsfeed from "../layouts/Newsfeed";
import SearchResult from "../layouts/SearchResult";
import { createBrowserHistory } from "history";
import CompanyProfile from "../layouts/CompanyProfile";
import JumpseatInvt from "../layouts/Jumpseat/JumpseatInvt";
import JumpSeatLogin from "../layouts/Jumpseat/JumpSeatLogin";
import Registration from "../layouts/Jumpseat/Registration";
import MyClients from "../layouts/MyClients";
import MyMatches from "../layouts/MyMatches";
import AgentSearch from "../layouts/AgentSearch";
import JumpseatMatches from "../layouts/Jumpseat/JumpseatMatches";
import PaymentPage from "../layouts/Jumpseat/PaymentPage";
import Footer from "../layouts/Footer";
import JumpseatRoutes from "./JumpseatRoutes";
import UserRoutes from "./UserRoutes";
import Impressum from "../layouts/Impressum";
import MembershipAgreement from "../layouts/MembershipAgreement";
import PrivacyPolicy from "../layouts/PrivacyPolicy";
import NotFound from "../layouts/NotFound";
import SalesPage from "../layouts/SalesPage";
import UserProfile from "../layouts/UserProfile";
import AdminPage from "../layouts/AdminPage";
import ResetPassword from "../layouts/ResetPassword";
import Blogs from "../layouts/Blogs";
import Maintenance from "../layouts/Maintenance";
import CookieConsent from "react-cookie-consent";
import JumpseatHsCodes from "../layouts/Jumpseat/JumpseatHsCodes";
import ClientsMatches from "../layouts/ClientsMatches";
import MyCustomers from "../layouts/MyCustomers";
import TradingCompanySalesPage from "../layouts/TradingCompanySalesPage";
import MyProducts from "../layouts/MyProducts";
import CommoditiesSearch from "../layouts/CommoditiesSearch";
import { connect } from "react-redux";
import { updateRoutes } from "../store/actions/breadcrumbsAction";
import { routesNames } from "./const";
import ProductDetails from "../layouts/ProductDetails";

export const customHistory = createBrowserHistory();

const Routes = ({ updateRoutes, routes }) => {
  customHistory.listen((route) => {
    const { pathname } = route;
    if (pathname.includes("/search/")) {
      updateRoutes({ name: "search", route: pathname }, routes);
    } else if (pathname.includes("/product/detail")) {
      updateRoutes({ name: "/product/detail", route: pathname }, routes);
    } else if (pathname.includes("/company-profile/user")) {
      updateRoutes({ name: "/company-profile/user", route: pathname }, routes);
    } else if (pathname.includes("/company-profile/")) {
      updateRoutes({ name: "/company-profile/", route: pathname }, routes);
    } else if (routesNames[pathname]) {
      updateRoutes({ name: pathname, route: pathname }, routes);
    }
    window.scrollTo(0, 0);
  });

  const history = window.location.href;

  return (
    <Router history={customHistory}>
      <div>
        {/* <Route exact component={withRouter(Maintenance)} /> */}
        {!history.includes("worldring") && (
          <Route exact component={withRouter(Maintenance)} />
        )}
        <Route exact component={withRouter(Navbar)} />
        <Switch>
          <Route exact path={"/"} component={withRouter(Login)} />
          <UserRoutes
            exact
            path={"/search/:searchVal"}
            component={withRouter(SearchResult)}
          />
          <UserRoutes
            exact
            path={"/newsfeed"}
            component={withRouter(Newsfeed)}
          />
          <JumpseatRoutes
            exact
            path={"/jumpseat/newsfeed"}
            component={withRouter(Newsfeed)}
          />
          <UserRoutes
            exact
            path={"/company-profile/:companyId"}
            component={withRouter(CompanyProfile)}
          />
          <UserRoutes
            exact
            path={"/company-profile/user/:companyId"}
            component={withRouter(UserProfile)}
          />
          <UserRoutes
            exact
            path={"/jumpseat/invite"}
            component={withRouter(JumpseatInvt)}
          />
          <Route exact path={"/join"} component={withRouter(JumpSeatLogin)} />
          <UserRoutes
            exact
            path={"/my-customers"}
            component={withRouter(MyCustomers)}
          />
          <JumpseatRoutes
            exact
            path={"/jumpseat/registration"}
            component={withRouter(Registration)}
          />
          <JumpseatRoutes
            exact
            path={"/jumpseat/my-matches"}
            component={withRouter(JumpseatMatches)}
          />
          <JumpseatRoutes
            exact
            path={"/jumpseat/hs-codes"}
            component={withRouter(JumpseatHsCodes)}
          />
          <PrivateRoute
            exact
            path={"/membership/payment"}
            component={withRouter(PaymentPage)}
          />
          <UserRoutes
            exact
            path={"/my-products"}
            component={withRouter(MyProducts)}
          />
          <UserRoutes
            exact
            path={"/commodities-search"}
            component={withRouter(CommoditiesSearch)}
          />
          <UserRoutes
            exact
            path={"/product/detail/:id"}
            component={withRouter(ProductDetails)}
          />
          <UserRoutes
            exacth
            path={"/my-clients"}
            component={withRouter(MyClients)}
          />
          <UserRoutes
            exact
            path={"/my-matches"}
            component={withRouter(MyMatches)}
          />
          <UserRoutes
            exact
            path={"/client-matches"}
            component={withRouter(ClientsMatches)}
          />
          <UserRoutes
            exact
            path={"/agent/search"}
            component={withRouter(AgentSearch)}
          />
          <Route exact path={"/impressum"} component={withRouter(Impressum)} />
          <Route
            exact
            path={"/membership-agreement"}
            component={withRouter(MembershipAgreement)}
          />
          <Route
            exact
            path={"/privacy-policy"}
            component={withRouter(PrivacyPolicy)}
          />
          <UserRoutes
            exact
            path={"/sales-page"}
            component={withRouter(SalesPage)}
          />
          <UserRoutes
            exact
            path={"/sales-support"}
            component={withRouter(TradingCompanySalesPage)}
          />
          <UserRoutes
            exact
            path={"/admin-page"}
            component={withRouter(AdminPage)}
          />
          <UserRoutes
            exact
            path={"/blog/newsfeed"}
            component={withRouter(Blogs)}
          />
          <Route
            exact
            path={"/reset-password/:token"}
            component={withRouter(ResetPassword)}
          />
          <Route path="*" component={NotFound} />
        </Switch>
        {!history.includes("my-customers") && (
          <Route exact component={withRouter(Footer)} />
        )}
      </div>
      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="WorldRingCookies"
        style={{}}
        buttonStyle={{
          marginBottom: "10px",
          color: "#fff",
          fontSize: "14px",
          background: "transparent",
          border: "2px solid white",
          padding: "5px 25px",
          fontWeight: "700",
          marginTop: "10px",
        }}
        expires={150}
        disableStyles={true}
      >
        <span style={{ fontSize: "14px", marginBottom: "0px" }}>
          This website uses cookies and third party services.{" "}
          <a
            style={{ color: "#bfbfbf", textDecoration: "none" }}
            href="https://worldring.org/en/cookie-policy-eu/"
            target={"_blank"}
          >
            View cookie policy
          </a>
          .
        </span>
      </CookieConsent>
    </Router>
  );
};

const mapStateToProps = (state) => {
  let routes = state.breadcrumbs.routes;
  return {
    routes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateRoutes: (route, routes) => dispatch(updateRoutes(route, routes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
