import React from "react";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {userLevelColors} from "../../services/globalFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons";

class AgentInfoModal extends React.Component {
    render() {
        const {agentInfo, country} = this.props;
        return (
            <Modal show={this.props.show} size={'lg'} onHide={this.props.onHide} className="CompanyServicesModal NewInviteModal">
                <Modal.Body style={{width: '400px'}}>
                    {
                        agentInfo &&
                            <div>
                                <div className="box text-center p-2">
                                    <img style={{maxHeight: '100px'}} alt={'...'} src={agentInfo.logo.data ? `data:image/jpeg;base64,${agentInfo.logo.data}` : 'https://via.placeholder.com/350x100.png?text=Company+Logo'} />
                                </div>
                                <div className="box main-contact mt-4 p-2">
                                    <h6 style={{color: 'grey'}}>Main Contact</h6>
                                    <div className="row">
                                        <div className="col-lg-4">
                                             <img alt={'...'} style={{maxWidth: '115px'}} src={agentInfo.contact_image ? agentInfo.contact_image.data.length > 0 ? `data:image/jpeg;base64,${agentInfo.contact_image.data}` : 'https://via.placeholder.com/100x150.png?text=Agent' : 'https://via.placeholder.com/100x150.png?text=Agent'} />
                                        </div>
                                        <div className="col-lg-8">
                                            <h5 className="mb-1">{agentInfo.main_contact_object.title} {agentInfo.main_contact_object.fname} {agentInfo.main_contact_object.lname}</h5>
                                            <h5 className="font-weight-light">{agentInfo.main_contact_object.jobtitle}</h5>

                                            <p>
                                                <FontAwesomeIcon
                                                    icon={faPhone}
                                                    className="mr-2"
                                                />
                                                {agentInfo.main_contact_object.phone}
                                            </p>
                                            <p style={{wordBreak: 'break-word', display: 'flex', alignItems: 'center'}}>
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                    className="mr-2"
                                                />
                                                {agentInfo.main_contact_object.email && <a href={`mailto:${agentInfo.main_contact_object.email}?subject=New match for you and your customer from ${country}! Request for details.`}>{agentInfo.main_contact_object.email}</a>}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="box mt-4 p-2">
                                    <h4 className="mb-1">{agentInfo.name}</h4>
                                    <h6 style={{fontSize: '14px', color: 'grey'}}>{agentInfo.hq_city}, {agentInfo.hq_country}</h6>
                                    <hr className="w-100"/>
                                    <h6 className="text-uppercase" style={{color: 'grey', fontSize: '14px', fontWeight: 'bold'}}>Headquarter</h6>
                                    <p className="m-0">{agentInfo.hq_address}, {agentInfo.hq_postcode}</p>
                                    <p>{agentInfo.hq_city}, {agentInfo.hq_country}</p>
                                </div>
                            </div>


                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <div>
                            <Button
                                className="btn btn-wlr"
                                style={{
                                    background: userLevelColors(this.props.level).buttonBg,
                                    color: userLevelColors(this.props.level).buttonFont,
                                    borderColor: userLevelColors(this.props.level).buttonFont
                                }}
                                variant="dark"
                                onClick={this.props.onHide}
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let companyIds = state.login.company_ids;
    let level = state.login.level;
    return {
        token, companyIds, level
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AgentInfoModal);