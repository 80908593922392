import axios from "axios";
import { base_url } from "./const";
import React from "react";

export function JsEncrypt() {
  var k = 13;

  this.encode = function (data) {
    var enc = "";

    for (var i = 0; i < data.length; i++) {
      // create block
      var a = data.charCodeAt(i);
      // bitwise XOR
      var b = a ^ k;
      enc = enc + String.fromCharCode(b);
    }

    return btoa(enc);
  };

  this.decode = function (data) {
    data = atob(data);

    var enc = "";

    for (var i = 0; i < data.length; i++) {
      // create block
      var a = data.charCodeAt(i);
      // bitwise XOR
      var b = a ^ k;
      enc = enc + String.fromCharCode(b);
    }

    return enc;
  };
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const uploadImages = (image, token) => {
  axios
    .request({
      method: "post",
      url: `${base_url}/upload/image`,
      data: image,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export function request(endpoint, method, dataIn, token) {
  return new Promise((resolve, reject) => {
    var opts = {
      method: method, // Method itself
      headers: {
        //                'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
      body: dataIn,
    };
    var url = base_url + endpoint;
    fetch(url, opts)
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          resolve(data.data[0]);
        } else {
          reject("error");
        }
      }) // Manipulate the data retrieved back, if we want to do something with it
      .catch((err) => reject(err)); // Do something with the error
  });
}

export function pdfRequest(endpoint, method, dataIn, token) {
  return new Promise((resolve, reject) => {
    var opts = {
      method: method, // Method itself
      headers: {
        //                'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
      },
      body: dataIn,
    };
    var url = base_url + endpoint;
    fetch(url, opts)
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          resolve(data.data.file_id);
        } else {
          reject("error");
        }
      }) // Manipulate the data retrieved back, if we want to do something with it
      .catch((err) => reject(err)); // Do something with the error
  });
}
export async function uploadImage(image, token) {
  return await request("/upload/image", "POST", image, token);
}

export async function uploadPdf(file, token) {
  return await pdfRequest("/upload/pdf", "POST", file, token);
}

export async function getHSCodes(code) {
  let proxyUrl = "https://cors-anywhere.herokuapp.com/",
    targetUrl = `https://www.tariffnumber.com/api/v1/cnSuggest?term=${code}`;
  return await fetch(proxyUrl + targetUrl)
    .then((blob) => blob.json())
    .then((data) => {
      return data;
    })
    .catch((e) => {
      return e;
    });
}

export function filterCountry(list, country) {
  let arr = [];
  list.map((item) => {
    if (item.hq_country === country) {
      arr.push(item);
    }
  });
  return arr;
}

export function getCountries(list) {
  let arr = [];
  for (let i = 0; i < list.length; i++) {
    let country = list[i].hq_country;
    arr.push(country);
  }
  return arr.sort();
}

export function deletePnS(pnsId, token) {
  return new Promise((resolve, reject) => {
    axios
      .request({
        method: "delete",
        url: `${base_url}/company/pns/${pnsId}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
        console.log(err);
      });
  });
}

export const translateData = (membership) => {
  switch (membership) {
    case "dk-first_class":
      return "First Class";
    case "dk-tmp":
      return "Temp";
    case "dk-jumpseat":
      return "Jumpseat";
    case "ag-founding_member":
      return "Founding Member";
    case "dk-business":
      return "Business Member";
    case "dk-economy":
      return "Economy Member";
    default:
      return membership;
  }
};

export const userLevelColors = (membership) => {
  switch (membership) {
    case "dk-jumpseat":
      return {
        iconColor: "#000",
        font: "#000",
        bg: "#C3C3C3",
        others: "#000",
        buttonFont: "#fff",
        buttonBg: "#000",
        label: "Jumpseat",
        icon: "jumpseat-wh.svg",
        className: " jumpseat-member ",
        background: "rgba(195,195,195,0.2)",
      };
    case "dk-tmp":
      return {
        iconColor: "#000",
        font: "#000",
        bg: "#C3C3C3",
        others: "#000",
        buttonFont: "#fff",
        buttonBg: "#000",
        label: "Temp",
        icon: "jumpseat-wh.svg",
        className: " jumpseat-member ",
        background: "rgba(195,195,195,0.2)",
      };
    case "dk-accepted":
      return {
        iconColor: "#000",
        font: "#000",
        bg: "#C3C3C3",
        others: "#000",
        buttonFont: "#fff",
        buttonBg: "#000",
        label: "Jumpseat",
        icon: "jumpseat-wh.svg",
        className: " jumpseat-member ",
        background: "rgba(195,195,195,0.2)",
      };
    case "dk-economy":
      return {
        iconColor: "#1e345a",
        font: "#1e345a",
        bg: "#fff",
        others: "#fff",
        buttonFont: "#fff",
        buttonBg: "#1e345a",
        label: "Economy Class",
        icon: "economy-wh.svg",
        className: " economy-member ",
        background: "#fff",
      };
    case "dk-business":
      return {
        iconColor: "#fff",
        font: "#fff",
        bg: "#1e345a",
        others: "#1e345a",
        buttonFont: "#1e345a",
        buttonBg: "#fff",
        label: "Business Class",
        icon: "business-wh.svg",
        className: " business-member ",
        background: "rgba(30,52,90,0.2)",
      };
    case "dk-first_class":
      return {
        iconColor: "#fff",
        font: "#fff",
        bg: "#d0ac51",
        others: "#d0ac51",
        buttonFont: "#d0ac51",
        buttonBg: "#fff",
        label: "First Class",
        icon: "first-wh.svg",
        className: " first-class-member ",
        background: "rgba(208,172,81,0.2)",
      };
    case "ag-founding_member":
      return {
        iconColor: "#FFFFFF",
        font: "#e2b007",
        bg: "#000",
        others: "#000",
        buttonFont: "#000",
        buttonBg: "#FFFFFF",
        label: "Founding Member",
        icon: "FoundingMember.png",
        className: " founding-member ",
        background: "rgba(0,0,0,0.2)'",
      };
    case "ag-member":
      return {
        iconColor: "#FFFFFF",
        font: "#C0C0C0",
        bg: "#000",
        others: "#000",
        buttonFont: "#000",
        buttonBg: "#FFFFFF",
        label: "Member",
        icon: "AgentLevel.png",
        className: " founding-member ",
        background: "rgba(0,0,0,0.2)'",
      };
    case "admin":
      return {
        iconColor: "#C2B59B",
        font: "#C2B59B",
        bg: "#4C0013",
        others: "#4C0013",
        buttonBg: "#C2B59B",
        buttonFont: "#4C0013",
        label: "Admin",
        icon: "admin-gold.svg",
        className: " admin-member ",
        background: "rgba(76,0,19,0.2)",
      };
    case "tk-default":
      return {
        iconColor: "#FFFFFF",
        font: "#FFFFFF",
        bg: "#004aad",
        others: "#004aad",
        buttonBg: "#FFFFFF",
        buttonFont: "#004aad",
        label: "Trading Company",
        icon: "AgentLevel.png",
        className: " trading-company ",
        background: "rgba(0,74,173,0.2)",
      };
    default:
      return {
        iconColor: "#C3C3C3",
        font: "#ffffff",
        bg: "#000",
        others: "#000",
        buttonBg: "#000",
        buttonFont: "#C3C3C3",
        label: "Agent",
        icon: "AgentLevel.png",
        className: " agent-member ",
        background: "rgba(0,0,0,0.2)",
      };
  }
};

export const paymentButtons = (userLevel) => {
  switch (userLevel) {
    case "dk-first_class":
      return {
        first: { label: "Contact us", value: 1 },
        second: { label: "Contact us", value: 1 },
        third: { label: "Your current plan", value: null },
      };
    case "dk-business":
      return {
        first: { label: "Contact us", value: 1 },
        second: { label: "Your current plan", value: null },
        third: { label: "Upgrade plan", value: "P-2848939415498134DL5ENOHQ" },
      };
    case "dk-economy":
      return {
        first: { label: "Your current plan", value: null },
        second: { label: "Upgrade plan", value: "P-25Y283259U926511LL5ENNJY" },
        third: { label: "Upgrade plan", value: "P-2848939415498134DL5ENOHQ" },
      };
    default:
      return {
        first: { label: "Choose economy", value: "P-9UB28031CL483774HL5ENL6I" },
        second: {
          label: "Choose business",
          value: "P-25Y283259U926511LL5ENNJY",
        },
        third: {
          label: "Choose first class",
          value: "P-2848939415498134DL5ENOHQ",
        },
      };
  }
};
export const ButtonSpinner = (props) => {
  return (
    <div
      style={{
        height: "1.3em",
        width: "1.3em",
        color: userLevelColors(props.level).bg,
      }}
      className={
        "spinner-border " +
        (props.showLoading ? "" : " d-none ") +
        (props.level ? "" : "text-light")
      }
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const SearchSpinner = (props) => {
  return (
    <div
      className={
        "col-lg-12 text-center spinner-stuff mt-5 pb-5 " +
        (props.showLoading ? "" : "d-none")
      }
    >
      <div
        style={{
          height: "4em",
          width: "4em",
          color: userLevelColors(props.level).bg,
        }}
        className={"spinner-border " + (props.level ? "" : "text-primary")}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export const JSMatchesSearchSpinner = (props) => {
  return (
    <div
      className={
        "col-lg-12 text-center spinner-stuff mt-5 pb-5 " +
        (props.showLoading ? "" : "d-none")
      }
    >
      <div style={{marginBottom:40, fontSize:16 , fontWeight:'bold'}}>We are looking for matches to your search/offer on the WorldRing Network.</div>
      <div
        style={{
          height: "4em",
          width: "4em",
          color: userLevelColors(props.level).bg,
        }}
        className={"spinner-border " + (props.level ? "" : "text-primary")}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
      <div style={{marginTop:40}}>Please allow up to 60 seconds for this process to complete.</div>
    </div>
  );
};

export const reorderAdminPage = (data) => {
  let sortedData = [];
  for (let i = 0; i < data.length; i++) {
    let row = data[i];
    for (let k in row) {
      let newRow = {
        name: row.name,
        country: row.country,
        membership: row.membership,
        onlineSince: row.onlineSince,
        aic: row.aic,
        type: row.type,
        hsCode: row.hsCode,
        details: row.details,
        targetGroup: row.target_group,
        specification: row.specification,
        enteredByAg: row.enteredByAg,
      };
      sortedData.push(newRow);
    }
  }
  return sortedData;
};

export const reorderSalesPage = (data, level) => {
  let sortedData = [];
  for (let i = 0; i < data.length; i++) {
    let row = data[i];
    let newRow = {
      country: row.country,
      type: row.type,
      hsCode: row.hsCode,
      details: row.details,
      targetGroup: row.target_group,
      specification: row.specification,
      membership: row.membership,
      onlineSince: row.onlineSince,
      [level === "tk-default" ? "customer" : "aic"]:
        level === "tk-default" ? row.customer : row.aic,
      number: row.number,
    };
    sortedData.push(newRow);
  }
  return sortedData;
};

export const PnSLimit = (level) => {
  switch (level) {
    case "dk-first_class":
      return 20;
    case "dk-business":
      return 10;
    case "dk-economy":
      return 5;
    default:
      return 20;
  }
};
