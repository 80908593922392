import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  PnSLimit,
  toBase64,
  uploadImage,
  uploadPdf,
  userLevelColors,
} from "../../../services/globalFunctions";
import { PnSFormComponent } from "../../CompanyProfileComponents/PnSFormComponent";
import { connect } from "react-redux";
import OSFormComponent from "../../CompanyProfileComponents/OSFormComponent";
import axios from "axios";
import { base_url, countryList } from "../../../services/const";
import { NotificationManager } from "react-notifications";
import classNames from "classnames";
// import Select from "react-select";

const options = countryList.map((i) => {
  return { value: i, label: i };
});

const INITIAL_STATE = {
  productImage: [],
  // servicesToShow: this.props.searches.length > 0 ? this.props.searches : [1],
  name: "",
  pns_id: "",
  country: "",
  companyNameForHSCode: "",
  countryForHSCode: { value: "", label: "Country" },
  companyNameForHSCodeError: false,
  countryForHSCodeError: false,
  companyName: "",
  description: "",
  target: "",
  specification: "",
  fileName: "",
  hsCode: "",
  pdf: "",
  type: "search",
  quantity: 1,
  weight: 0,
  minUnits: 1,
  certification: "false",
  season: "false",
  dimension: "0,0,0",
  modeOfProduction: "",
  capacity: "",
  color: "",
  minPrice: 0,
  maxPrice: 0,
  incoterm: "",
  filteredClients: [],
  certificationDescription: "",
  seasonalityDescription: "",
  showDescriptionError: false,
  showTitleError: false,
  showHsCodeError: false,
};
class ProductModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      image: [],
      itemToEdit: {},
    };
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeSpecs = this.onChangeSpecs.bind(this);
    this.onChangeTarget = this.onChangeTarget.bind(this);
    this.onChangeFileName = this.onChangeFileName.bind(this);
    this.onChangeCountru = this.onChangeCountru.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.handleServicesImage = this.handleServicesImage.bind(this);
    this.addNewProduct = this.addNewProduct.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.handlePdfUpload = this.handlePdfUpload.bind(this);
    this.onChangeHsCode = this.onChangeHsCode.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.onChangeWeight = this.onChangeWeight.bind(this);
    this.onChangeSeason = this.onChangeSeason.bind(this);
    this.onChangeDimension = this.onChangeDimension.bind(this);
    this.onChangeModeOfProduction = this.onChangeModeOfProduction.bind(this);
    this.onChangeCapacity = this.onChangeCapacity.bind(this);
    this.onChangeColor = this.onChangeColor.bind(this);
    this.onChangeMaxPrice = this.onChangeMaxPrice.bind(this);
    this.onChangeMinPrice = this.onChangeMinPrice.bind(this);
    this.onChangeIncoterm = this.onChangeIncoterm.bind(this);
    this.onChangeMinUnits = this.onChangeMinUnits.bind(this);
    this.onChangeCertification = this.onChangeCertification.bind(this);
    this.getItemToEdit = this.getItemToEdit.bind(this);
    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onClickClientCompany = this.onClickClientCompany.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.onChangeCertificationDescription =
      this.onChangeCertificationDescription.bind(this);
    this.onChangeSeasonalityDescription =
      this.onChangeSeasonalityDescription.bind(this);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.isEditingItem &&
      this.props.itemToEdit !== prevProps.itemToEdit &&
      Object.keys(this.props.itemToEdit).length > 0
    ) {
      await this.getItemToEdit();
    }
    if (
      this.props.clientsHsCode !== prevProps.clientsHsCode &&
      this.props.clientsHsCode
    ) {
      this.props.getAllClients();
    }
    if (this.props.show !== prevProps.show && !this.props.show) {
      this.setState({ ...INITIAL_STATE, image: [], itemToEdit: {} });
    }
  }

  onChangeSeasonalityDescription(e) {
    this.setState({ seasonalityDescription: e });
  }

  onChangeCertificationDescription(e) {
    this.setState({ certificationDescription: e });
  }

  onChangeName(val) {
    this.setState({ name: val, showTitleError: false });
  }

  onChangeDescription(val) {
    this.setState({ description: val, showDescriptionError: false });
  }

  onChangeTarget(val) {
    this.setState({ target: val });
  }

  onChangeSpecs(val) {
    this.setState({ specification: val });
  }

  onChangeFileName(val) {
    this.setState({ fileName: val });
  }

  onChangeHsCode(val) {
    this.setState({ hsCode: val, showHsCodeError: false });
  }

  onChangeQuantity(val) {
    this.setState({ quantity: val });
  }

  onChangeWeight(val) {
    this.setState({ weight: val });
  }

  onChangeMinUnits(val) {
    this.setState({ minUnits: val });
  }

  onChangeCertification(val) {
    this.setState({ certification: val });
  }

  onChangeSeason(val) {
    this.setState({ season: val });
  }

  onChangeIncoterm(val) {
    let strVal = "";
    for (let i = 0; i < val.length; i++) {
      strVal += `${val[i].value}${i !== val.length - 1 ? "," : ""}`;
    }
    this.setState({ incoterm: strVal });
  }

  onChangeDimension(val, key) {
    const { dimension } = this.state;
    let arr = dimension.split(",");
    const obj = {
      length: arr[0],
      width: arr[1],
      height: arr[2],
    };
    obj[key] = val;
    this.setState({ dimension: `${obj.length},${obj.width},${obj.height}` });
  }

  onChangeModeOfProduction(val) {
    this.setState({ modeOfProduction: val });
  }

  onChangeCapacity(val) {
    this.setState({ capacity: val });
  }

  onChangeColor(val) {
    this.setState({ color: val });
  }

  onChangeMaxPrice(val) {
    this.setState({ maxPrice: val });
  }

  onChangeMinPrice(val) {
    this.setState({ minPrice: val });
  }

  async onClickSubmit() {
    if (this.props.clientHSCodeModal) {
      if (!this.props.isEditingItem) {
        if (
          !this.state.hsCode ||
          !this.state.name ||
          !this.state.description ||
          !this.state.countryForHSCode.value ||
          !this.state.companyNameForHSCode
        ) {
          if (!this.state.hsCode) {
            this.setState({ showHsCodeError: true });
          }
          if (!this.state.name) {
            this.setState({ showTitleError: true });
          }
          if (!this.state.description) {
            this.setState({ showDescriptionError: true });
          }
          if (!this.state.countryForHSCode.value) {
            this.setState({ countryForHSCodeError: true });
          }
          if (!this.state.companyNameForHSCode) {
            this.setState({ companyNameForHSCodeError: true });
          }
          return;
        }
      } else {
        if (
          !this.state.name ||
          !this.state.description ||
          !this.state.countryForHSCode.value ||
          !this.state.companyNameForHSCode
        ) {
          if (!this.state.name) {
            this.setState({ showTitleError: true });
          }
          if (!this.state.description) {
            this.setState({ showDescriptionError: true });
          }
          if (!this.state.countryForHSCode.value) {
            this.setState({ countryForHSCodeError: true });
          }
          if (!this.state.companyNameForHSCode) {
            this.setState({ companyNameForHSCodeError: true });
          }
          return;
        }
      }
    } else {
      if (!this.props.isEditingItem) {
        if (!this.state.hsCode || !this.state.name || !this.state.description) {
          if (!this.state.hsCode) {
            this.setState({ showHsCodeError: true });
          }
          if (!this.state.name) {
            this.setState({ showTitleError: true });
          }
          if (!this.state.description) {
            this.setState({ showDescriptionError: true });
          }
          return;
        }
      } else {
        if (!this.state.name || !this.state.description) {
          if (!this.state.name) {
            this.setState({ showTitleError: true });
          }
          if (!this.state.description) {
            this.setState({ showDescriptionError: true });
          }
          return;
        }
      }
    }

    await this.addNewProduct();
    // for(let k = 0; k < this.state.servicesToShow.length; k++) {
    //     arr.push(this.state.servicesToShow[k].pns_id);
    // }
    // for(let i in this.state.valObj) {
    //     if(arr.includes(parseInt(i))) {
    //         this.state.valObj[i] ? await this.updateProduct(this.state.valObj[i], i) : void 0;
    //     } else {
    //         this.state.valObj[i] ? await this.addNewProduct(this.state.valObj[i]) : void 0;
    //     }
    // }
  }

  async handlePdfUpload(file) {
    let formData = new FormData();
    const { token } = this.props;
    formData.append("pdf", file);
    let pdf_id = await uploadPdf(formData, token);
    this.setState({ pdf: pdf_id });
  }

  async handleServicesImage(file) {
    let formData = new FormData();
    const { token } = this.props;
    const { image } = this.state;
    formData.append("image", file);
    let image_id = await uploadImage(formData, token);
    image.push(image_id);
    this.setState({ image: image });
  }

  onChangeType(type) {
    this.setState({ type: type });
  }

  getItemToEdit() {
    return new Promise((resolve, reject) => {
      const { token, itemToEdit } = this.props;
      axios
        .request({
          method: "get",
          url: `${base_url}/company/pns/details/${itemToEdit.pns_id}`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((ret) => {
          if (ret.data.status === 200 && ret.data.data) {
            this.setState({
              itemToEdit: ret.data.data,
              pns_id: ret.data.data.pns_id,
              companyNameForHSCode: this.props.companyNameForEdit || "",
              countryForHSCode: {
                value: this.props.countryForEdit || "",
                label: this.props.countryForEdit || "",
              },
            });
          }
          resolve();
        })
        .catch((err) => resolve());
    });
  }

  addNewProduct() {
    return new Promise((resolve, reject) => {
      const {
        token,
        companyIds,
        fileName,
        isEditingItem,
        itemToEdit,
        clientsHsCode,
      } = this.props;
      const {
        seasonalityDescription,
        certificationDescription,
        name,
        description,
        target,
        specification,
        incoterm,
        hsCode,
        pdf,
        image,
        type,
        quantity,
        weight,
        minUnits,
        certification,
        season,
        dimension,
        modeOfProduction,
        capacity,
        color,
        minPrice,
        maxPrice,
        selectedCompanyId,
      } = this.state;
      let params = new URLSearchParams();
      let url;
      if (!name && !description) {
        this.setState({
          showDescriptionError: true,
          showTitleError: true,
        });
        return false;
      } else if (!name) {
        this.setState({ showTitleError: true });
        return false;
      } else if (!description) {
        this.setState({ showDescriptionError: true });
        return false;
      }
      this.setState({
        showDescriptionError: false,
        showTitleError: false,
      });
      if (clientsHsCode) {
        if (itemToEdit) {
          const obj = this.state.itemToEdit;
          const id = obj.company.company_id;
          if (this.props.clientHSCodeModal) {
            url = `${base_url}/company/pns/${itemToEdit.pns_id}`;
          } else {
            url = `${base_url}/company/pns/${id}/${itemToEdit.pns_id}`;
          }
        } else {
          if (this.props.clientHSCodeModal) {
            url = `${base_url}/company/pns/tmp`;
          } else {
            url = `${base_url}/company/pns/${selectedCompanyId}`;
          }
        }
      } else {
        url = isEditingItem
          ? `${base_url}/company/pns/${companyIds[0]}/${itemToEdit.pns_id}`
          : `${base_url}/company/pns/${companyIds[0]}`;
      }
      image.length > 0
        ? params.append("image_ids", JSON.stringify(image))
        : void 0;
      params.append("type", type);

      name ? params.append("name", name) : void 0;
      description ? params.append("description", description) : void 0;
      fileName ? params.append("file_name", fileName) : void 0;
      target ? params.append("target_group", target) : void 0;
      specification ? params.append("specification", specification) : void 0;
      pdf ? params.append("company_ps_pdf_id", pdf) : void 0;
      hsCode ? params.append("hs_codes_id", hsCode.id) : void 0;
      color ? params.append("colour", color) : void 0;
      quantity ? params.append("quantity", quantity) : void 0;
      weight ? params.append("weight", weight) : void 0;
      minUnits ? params.append("min_units", minUnits) : void 0;
      minPrice ? params.append("min_price", minPrice) : void 0;
      maxPrice ? params.append("max_price", maxPrice) : void 0;
      capacity ? params.append("capacity", capacity) : void 0;
      certification
        ? params.append("certification", certification === "true")
        : void 0;
      season ? params.append("seasonality", season) : void 0;
      dimension ? params.append("dimensions", dimension) : void 0;
      modeOfProduction
        ? params.append("mode_of_production", modeOfProduction)
        : void 0;
      incoterm ? params.append("incoterm", incoterm) : void 0;
      certificationDescription
        ? params.append("certification_description", certificationDescription)
        : void 0;
      seasonalityDescription
        ? params.append("seasonality_description", seasonalityDescription)
        : void 0;
      if (this.props.clientHSCodeModal) {
        this.state.companyNameForHSCode
          ? params.append("company_name", this.state.companyNameForHSCode)
          : void 0;
        this.state.countryForHSCode.value
          ? params.append("country", this.state.countryForHSCode.value)
          : void 0;
      }
      axios
        .request({
          method: isEditingItem ? "post" : "put",
          url: url,
          data: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            NotificationManager.success("Product and Services Added");
            this.props.onHide();
            this.props.getPnS();
            resolve(res);
          } else {
            NotificationManager.error("Something went wrong. Try again");
          }
        });
    });
  }

  deleteProduct() {
    return new Promise((resolve, reject) => {
      const { token } = this.props;
      const { pns_id } = this.state;
      let params = new URLSearchParams();

      pns_id ? params.append("pns_id", pns_id) : void 0;

      axios
        .request({
          method: "delete",
          url: `${base_url}/company/pns/${pns_id}`,
          data: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            NotificationManager.success("Product removed");
            this.props.onHide();
            this.props.getPnS();
            resolve(res);
          } else {
            NotificationManager.error("Something went wrong. Try again");
          }
        });
    });
  }

  updateProduct(obj, id) {
    // return new Promise((resolve, reject) => {
    //     let params = new URLSearchParams();
    //     obj.image ? params.append('company_ps_image_id', obj.image) : void 0;
    //     params.append('type', 'search');
    //     obj.name ? params.append('name', obj.name) : void 0;
    //     obj.description ? params.append('description', obj.description) : void 0;
    //     obj.target ? params.append('target_group', obj.target) : void 0;
    //     obj.specification ? params.append('specification', obj.specification) : void 0;
    //     obj.pdf ? params.append('company_ps_file_id', obj.pdf) : void 0;
    //     obj.hsCode ? params.append('hs_codes_id', obj.hsCode[0].id) : void 0;
    //     axios.request({
    //         method: 'post',
    //         url: `${base_url}/company/pns/${this.props.companyId}/${id}`,
    //         data: params,
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //             'Authorization': `Bearer ${this.props.token}`
    //         }
    //     }).then((res) => {
    //         if(res.status === 200 && res.data.status === 200) {
    //             NotificationManager.success('Product and Services Updated');
    //             this.props.onHide();
    //             this.props.getPnS();
    //             resolve(res);
    //         } else {
    //             NotificationManager.error('Something went wrong. Try again');
    //         }
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // })
  }

  onClickClientCompany(e) {
    this.setState({
      companyName: e.company_name,
      country: e.country,
      fname: e.fname,
      lname: e.lname,
      filteredClients: [],
      selectedCompanyId: e.company_id,
    });
  }

  onChangeCountru(e) {
    this.setState({
      countryForHSCode: { value: e.value, label: e.label },
      countryForHSCodeError: false,
    });
  }

  onChangeCompanyName(e) {
    this.setState({
      companyNameForHSCode: e.target.value,
      companyNameForHSCodeError: false,
    });
    const { allClients } = this.props;
    let value = e.target.value;
    this.setState({ companyName: value });
    if (value.length >= 3) {
      let filter = allClients.filter((e) =>
        e.company_name.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ filteredClients: filter });
    } else {
      this.setState({ filteredClients: [] });
    }
  }

  render() {
    const { isEditingItem, typeAdding, clientsHsCode } = this.props;
    const customStyle = {
      control: (provided) => ({
        ...provided,
        height: 10,
        width: 300,
        padding: 10,
        margin: 0,
        marginLeft: 0,
        border: "0px solid black",
        fontSize: 13,
        backgroundColor: "white",
        outline: "none",
      }),
    };

    const {
      itemToEdit,
      filteredClients,
      companyName,
      showTitleError,
      showDescriptionError,
      showHsCodeError,
    } = this.state;
    return (
      <Modal
        show={this.props.show}
        size={"lg"}
        onHide={this.props.onHide}
        className="CompanyServicesModal"
      >
        <Modal.Header
          closeButton
          style={{ background: userLevelColors(this.props.level).bg }}
        >
          <h3
            style={{
              color: userLevelColors(this.props.level).font,
              textTransform: "capitalize",
            }}
          >
            Detailed {typeAdding ? typeAdding : "Product"}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <p>
            Here you have the possibility to show a range of products and
            services in detail to your fellow network members. You can upload
            pictures for each item to make it highly visible.
          </p>
          {clientsHsCode && !this.props.clientHSCodeModal && (
            <input
              // disabled={this.props.editingOrNew === "edit"}
              onChange={this.onChangeCompanyName}
              type="text"
              // className={
              //   "mb-3 form-control " +
              //   userLevelColors(this.props.level).className
              // }
              className={classNames(
                "mb-3 form-control",
                userLevelColors(this.props.level).className,
                { "input-error": this.state.companyNameForHSCodeError }
              )}
              placeholder="Client Company Name*"
              value={companyName}
            />
          )}

          {this.props.clientHSCodeModal && (
            <input
              // disabled={this.props.editingOrNew === "edit"}
              onChange={(e) => {
                this.setState({
                  companyNameForHSCode: e.currentTarget.value,
                  companyNameForHSCodeError: false,
                });
              }}
              type="text"
              // className={
              //   "mb-3 form-control " +
              //   userLevelColors(this.props.level).className
              // }
              className={classNames(
                "mb-3 form-control",
                userLevelColors(this.props.level).className,
                { "input-error": this.state.companyNameForHSCodeError }
              )}
              placeholder="Client Company Name*"
              value={this.state.companyNameForHSCode}
            />
          )}

          {/* {this.props.clientHSCodeModal && (
            <div
              // styles={{ marginBottom: "15px" }}
              className={classNames("mb-3", "reacrSelectHScountry", {
                "input-error": this.state.companyNameForHSCodeError,
              })}
            >
              <Select
                options={[
                  {
                    label: "Country",
                    value: "",
                    isDisabled: true,
                  },
                  ...options,
                ]}
                placeholder="Country"
                onChange={(e) => {
                  this.setState({
                    countryForHSCode: { value: e.value, label: e.label },
                    countryForHSCodeError: false,
                  });
                }}
                value={this.state.countryForHSCode}
                defaultValue={{ label: "Country", value: "", isDisabled: true }}
              />
            </div>
          )} */}
          <div
            style={
              filteredClients.length > 0
                ? {
                    maxHeight: "200px",
                    borderRadius: "4px",
                    overflow: "scroll",
                    zIndex: "10",
                  }
                : {}
            }
            className={
              "position-absolute mt-3 col-lg-12 pl-0 pr-4 w-100 " +
              (filteredClients.length > 0 ? "" : "d-none")
            }
          >
            <ul className="list-group w-100">
              {filteredClients.map((item, i) => {
                return (
                  <li
                    style={{ cursor: "pointer" }}
                    key={i}
                    className="list-group-item"
                    onClick={this.onClickClientCompany.bind(null, item)}
                  >
                    {item.company_name}
                  </li>
                );
              })}
            </ul>
          </div>
          <OSFormComponent
            companyNameForHSCodeError={this.state.companyNameForHSCodeError}
            showDescriptionError={showDescriptionError}
            showTitleError={showTitleError}
            showHsCodeError={showHsCodeError}
            typeAdding={typeAdding}
            isEditing={isEditingItem}
            itemToEdit={itemToEdit}
            onChangeSeasonalityDescription={this.onChangeSeasonalityDescription}
            onChangeCertificationDescription={
              this.onChangeCertificationDescription
            }
            clientHSCodeModal={this.props.clientHSCodeModal}
            countryForHSCodeError={this.state.countryForHSCodeError}
            onChangeCountru={this.onChangeCountru}
            countryForHSCode={this.state.countryForHSCode}
            onChangeName={this.onChangeName}
            onChangeDescription={this.onChangeDescription}
            onChangeTarget={this.onChangeTarget}
            onChangeSpecs={this.onChangeSpecs}
            onChangeFileName={this.onChangeFileName}
            onChangeImage={this.handleServicesImage}
            onChangePdf={this.handlePdfUpload}
            onChangeType={this.onChangeType}
            onChangeHsCode={this.onChangeHsCode}
            onChangeQuantity={this.onChangeQuantity}
            onChangeWeight={this.onChangeWeight}
            onChangeMinUnits={this.onChangeMinUnits}
            onChangeCertification={this.onChangeCertification}
            onChangeSeason={this.onChangeSeason}
            onChangeDimension={this.onChangeDimension}
            onChangeModeOfProduction={this.onChangeModeOfProduction}
            onChangeColor={this.onChangeColor}
            onChangeCapacity={this.onChangeCapacity}
            onChangeMinPrice={this.onChangeMinPrice}
            onChangeMaxPrice={this.onChangeMaxPrice}
            onChangeIncoterm={this.onChangeIncoterm}
            onChangeCompanyName={this.onChangeCompanyName}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primory"
            style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
            onClick={this.deleteProduct}
          >
            Delete
          </Button>
          <Button
            variant="secondary"
            style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
            onClick={this.props.onHide}
          >
            Close
          </Button>
          <Button
            variant="dark"
            style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
            onClick={this.onClickSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let level = state.login.level;
  let token = state.login.token;
  let companyIds = state.login.company_ids;
  return {
    level,
    token,
    companyIds,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
