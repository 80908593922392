import {LOGIN_MAINTENANCE} from "./actionTypes";
import {maintenance_pw, maintenance_username} from "../../services/const";

export const loginMaintenance = (ret) => {
    return (dispatch) => {
        dispatch(setUserLogin(ret));
    }
};

function setUserLogin(ret) {
    return {
        type: LOGIN_MAINTENANCE,
        payload: ret
    }
};
