import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {store} from './../store/store'

function isUser() {
    try {
        if((store.getState().login.level !== 'dk-jumpseat' && store.getState().login.level !== 'dk-accepted') && store.getState().login.isLoggedIn) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.log(e);
    }
}

const UserRoutes = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isUser() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: `/jumpseat/registration`, state: { from: props.location } }} />
                )
            }
        />
    )
};

export default UserRoutes;
