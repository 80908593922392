import {LOGIN_MAINTENANCE} from "../actions/actionTypes";

const INITIAL_STATE = {
    isLoggedIn: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_MAINTENANCE:
            return Object.assign({}, state, {
                isLoggedIn: action.payload
            });
        default:
            return state;
    }
}
