import React from "react";
import {authenticateUser} from "../store/actions/loginAction";
import {connect} from "react-redux";
import axios from 'axios';
import {base_url, countryList} from "../services/const";
import {SearchResultListItem} from "../components/SearchResultListItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {filterCountry, getCountries, userLevelColors, SearchSpinner} from "../services/globalFunctions";
import {Link} from "react-router-dom";

class MyClients extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myClients: [],
            myClientsCc: [],
            countries: countryList,
            searchVal: '',
            showLoading: false
        };
        this.getMyClients = this.getMyClients.bind(this);
        this.onChangeCountryFilter = this.onChangeCountryFilter.bind(this);
    }

    componentDidMount() {
        this.getMyClients();
    }

    getMyClients() {
        this.setState({showLoading: true});
        axios
            .request({
                method: "get",
                url: `${base_url}/agent/myclients/${this.state.searchVal}`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${this.props.token}`,
                },
            })
            .then((ret) => {
                if (ret.status === 200 && ret.data.data.companies.length > 0) {
                    let arr = [];
                    ret.data.data.companies.map((item) => {
                        if(item.company_id !== 202) {
                            arr.push(item);
                        }
                    })
                    let countries = [...new Set(getCountries(arr))];
                    this.setState({
                        countries: countries,
                        myClients: arr,
                        myClientsCc: arr,
                        showLoading: false
                    });
                } else {
                    this.setState({showLoading: false});
                }
            })
            .catch((err) => {
                this.setState({showLoading: false});
                console.log(err);
            });
    }

    onChangeCountryFilter(e) {
        if(e.target.value === "") {
            this.getMyClients();
        } else {
            this.setState({myClients: filterCountry(this.state.myClientsCc, e.target.value)});
        }
    }

    render() {
        return (
            <div className="page-container MyClientContainer">
                <div className={"page-header mb-5"}>
                    <div
                        className="form-group col-5 position-absolute topsearch-container row"
                        style={{
                            top: "250px",
                        }}
                    >
                        <h1 className="search-heading">My Clients</h1>
                        <input
                            className="form-control topsearch"
                            placeholder="Search.."
                            value={this.state.searchVal}
                            onChange={(e) => this.setState({searchVal: e.target.value})}
                        />
                        <button
                            type="button"
                            className="navbar-search-btn bg-transparent border-0"
                            onClick={this.getMyClients}
                        >
                            <FontAwesomeIcon icon={faSearch}/>
                        </button>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <h4 className="font-weight-light"></h4>
                        </div>
                        <div className="col-lg-8 col-sm-12 col-sm-12 row">
                            <div className="col-4 ml-auto">
                                <select
                                    onChange={this.onChangeCountryFilter}
                                    className="form-control border-right-0 border-left-0 border-top-0 text-uppercase"
                                    style={{borderRadius: 0}}
                                >
                                    <option value={""}>Country</option>
                                    {
                                        this.state.countries.map((item, i) => {
                                            return <option key={i} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {/*<div className="col-4">*/}
                            {/*    <select*/}
                            {/*        className="form-control border-right-0 border-left-0 border-top-0 text-uppercase"*/}
                            {/*        style={{borderRadius: 0}}*/}
                            {/*    >*/}
                            {/*        <option>Industry</option>*/}
                            {/*        <option>2</option>*/}
                            {/*        <option>3</option>*/}
                            {/*        <option>4</option>*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <SearchSpinner showLoading={this.state.showLoading}/>
                    <div className={"row mt-5 " + (this.state.showLoading ? "d-none" : "")}>
                        <div className="col-lg-12">
                        {this.state.myClients.length > 0
                            ? this.state.myClients.map((item, i) => {
                                return <SearchResultListItem user={this.props.level} {...item} key={i} status={item.status} showStatus={true} />;
                            })
                            :
                            <div className="searchresult w-100 p-3 mb-4 position-relative col-lg-12">
                                <div
                                    className="col-lg-12"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div className="col-lg-2">
                                        <img
                                            style={{
                                                height: "70px",
                                                width: "auto",
                                                objectFit: "contain",
                                                maxWidth: '200px'
                                            }}
                                            src={require('../assets/images/WorldRing.png')}

                                            alt="..."
                                        />
                                    </div>
                                    <div className="col-lg-6 text-center">
                                        No clients have registered yet.
                                    </div>
                                    <div className="col-lg-4 text-right">
                                        <Link style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}} className="btn btn-wlr text-uppercase" to={'/jumpseat/invite'}>
                                            Invite Clients
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let isLoggedIn = state.login.isLoggedIn;
    let token = state.login.token;
    let level = state.login.level;
    return {
        isLoggedIn,
        token,
        level
    };
};

const mapDispatchToProps = (dispatch) => ({
    authenticateUser: (data) => dispatch(authenticateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyClients);
