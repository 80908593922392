import React from "react";
import { authenticateUser } from "../store/actions/loginAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { customHistory } from "../services/appRoutes";
import SlideInNav from "./SlideInNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faSearch,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import JumpseatSlideInMenu from "./Jumpseat/JumpseatSlideInMenu";
import { userLevelColors } from "../services/globalFunctions";
import { routesNames } from "../services/const";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: "",
      showSlideInMenu: false,
      showJumpseatMenu: false,
      sticky: false,
    };
    this.noShowUponLogin = this.noShowUponLogin.bind(this);
    this.showUponLogin = this.showUponLogin.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.closeSlideInMenu = this.closeSlideInMenu.bind(this);
    this.showSlideInMenu = this.showSlideInMenu.bind(this);
    this.conditionsToNowShowBreadCrumbs =
      this.conditionsToNowShowBreadCrumbs.bind(this);
    this.breadCrumbsStyles = this.breadCrumbsStyles.bind(this);
  }

  componentDidMount() {
    this.showSlideInMenu();
    document.addEventListener("scroll", () => {
      window.scrollY > 56
        ? this.setState({ sticky: true })
        : this.setState({ sticky: false });
    });
  }

  noShowUponLogin() {
    if (this.props.isLoggedIn) {
      return "d-none";
    } else {
      return "";
    }
  }

  showSlideInMenu() {
    if (
      (this.props.location.pathname.includes("jumpseat") ||
        this.props.location.pathname.includes("payment")) &&
      !this.props.location.pathname.includes("invite")
    ) {
      return "d-none";
    }
  }

  showUponLogin() {
    if (this.props.isLoggedIn) {
      return "";
    } else {
      return "invisible";
    }
  }

  onClickSearch(e) {
    e.preventDefault();
    if (this.state.searchVal.length >= 3) {
      customHistory.push(`/search/${this.state.searchVal}`);
    }
  }

  closeSlideInMenu() {
    this.setState({ showSlideInMenu: false, showJumpseatMenu: false });
  }

  conditionsToNowShowBreadCrumbs() {
    const { level } = this.props;
    return level !== "dk-jumpseat";
  }

  breadCrumbsStyles() {
    const { location } = this.props;
    if (location.pathname.includes("company-profile")) {
      return {
        position: "absolute",
        top: 420,
        background: "white",
        width: "100%",
        padding: "15px",
        zIndex: 9,
      };
    } else if (location.pathname.includes("/agent/search")) {
      return {
        position: "absolute",
        top: "53vh",
        background: "white",
        width: "100%",
        padding: "15px",
        zIndex: 9,
      };
    } else {
      return {
        position: "absolute",
        top: 312,
        background: "white",
        width: "100%",
        padding: "15px",
        zIndex: 9,
      };
    }
  }

  render() {
    const { routes } = this.props;
    return (
      <div className={this.showUponLogin()}>
        <nav
          className={`page-navbar navbar navbar-expand-lg navbar-light bg-white ${
            this.state.sticky ? "nav-moving" : "nav-static"
          }`}
          style={{ padding: ".98rem 1rem" }}
        >
          <div
            className="collapse navbar-collapse container"
            id="navbarSupportedContent"
          >
            <form
              className={"position-relative form-inline my-2 my-lg-0 w-100 "}
              onSubmit={this.onClickSearch}
            >
              <div className="logo-container">
                <Link to={"/"} style={{ display: "block" }}>
                  <img
                    className={`wlr-logo ${
                      this.state.sticky ? "logo-restricted" : "logo-expanded"
                    }`}
                    style={{ zIndex: "10" }}
                    src={
                      this.state.sticky
                        ? require("../assets/brand/logo-restricted.svg")
                        : require("../assets/brand/logo.svg")
                    }
                    alt="brand"
                  />
                </Link>
              </div>
              {/*<div className={"form-group w-100 " + this.showUponLogin() + (this.props.level === 'dk-jumpseat' ? "invisible" : "")}>*/}
              {/*        <input*/}
              {/*            type="text"*/}
              {/*            className={"form-control nav-search border-top-0 border-right-0 border-left-0 col-10 " + (userLevelColors(this.props.level).className)}*/}
              {/*            defaultValue={this.state.searchVal}*/}
              {/*            onChange={(e) => this.setState({searchVal: e.target.value})}*/}
              {/*            placeholder="Search..."*/}
              {/*        />*/}
              {/*        <button*/}
              {/*            type="button"*/}
              {/*            className="navbar-search-btn bg-transparent border-0"*/}
              {/*            onClick={this.onClickSearch}*/}
              {/*        >*/}
              {/*            <FontAwesomeIcon icon={faSearch}/>*/}
              {/*        </button>*/}
              {/*</div>*/}
            </form>
            <form className={"form-inline my-2 my-lg-0 w-100"}>
              <ul className="navbar-nav ml-auto">
                {/*<li className="nav-item" style={{visibility: 'hidden'}}>*/}
                {/*    <Link*/}
                {/*        to={"/join"}*/}
                {/*        className={"nav-link " + this.noShowUponLogin()}*/}
                {/*        href="#"*/}
                {/*        style={{*/}
                {/*            fontSize: "14px"*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Join now*/}
                {/*    </Link>*/}
                {/*</li>*/}
                <li className="nav-item ">
                  <Link
                    to={"/"}
                    className={"nav-link " + this.noShowUponLogin()}
                    href="#"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Member Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/"}
                    className={"nav-link " + this.showUponLogin()}
                    href="#"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Welcome Back, <strong>{this.props.fname}</strong>
                  </Link>
                </li>
                <li className={"nav-item ml-3 "}>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    className={"mt-2 " + this.showUponLogin()}
                    size={"lg"}
                    icon={faBars}
                    onClick={() => {
                      this.props.level === "dk-jumpseat"
                        ? this.setState((prevState) => ({
                            showJumpseatMenu: !prevState.showJumpseatMenu,
                          }))
                        : this.setState((prevState) => ({
                            showSlideInMenu: !prevState.showSlideInMenu,
                          }));
                    }}
                  />
                </li>
              </ul>
            </form>
          </div>
        </nav>
        {this.props.routes.length > 0 && this.conditionsToNowShowBreadCrumbs() && (
          <div style={this.breadCrumbsStyles()} className="box">
            <div className="container">
              {this.props.routes.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <Link
                      to={item.route}
                      style={{ color: "black" }}
                      className="breadcrumb-item"
                    >
                      {item.name === "/product/detail"
                        ? this.props.activeProduct
                        : item.name === "/company-profile/"
                        ? this.props.activeCompany
                        : routesNames[item.name]}
                    </Link>
                    {/*{*/}
                    {/*    console.log(routes, routes.length)*/}
                    {/*}*/}
                    {i + 1 === routes.length ? "" : " > "}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}

        <SlideInNav
          showMenu={this.state.showSlideInMenu}
          closeMenu={this.closeSlideInMenu}
        />
        <JumpseatSlideInMenu
          showMenu={this.state.showJumpseatMenu}
          closeMenu={this.closeSlideInMenu}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isLoggedIn = state.login.isLoggedIn;
  let userRole = state.login.group;
  let fname = state.login.fname;
  let level = state.login.level;
  let routes = state.breadcrumbs.routes;
  let activeProduct = state.breadcrumbs.activeProduct;
  let activeCompany = state.breadcrumbs.activeCompany;
  return {
    isLoggedIn,
    routes,
    fname,
    userRole,
    level,
    activeCompany,
    activeProduct,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (data) => dispatch(authenticateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
