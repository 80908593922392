import {CLEAR_PROPS, LOGIN_AUTHENTICATION, UPDATE_USER_LEVEL} from "../actions/actionTypes";

const INITIAL_STATE = {
    isLoggedIn: false,
    user_id: "",
    email: "",
    fname: "",
    lname: "",
    level: "",
    token: "",
    company_ids: "",
    group: "",
    ag_contact: {},
    company_logo: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_AUTHENTICATION:
            return Object.assign({}, state, {
                isLoggedIn: true,
                email: action.payload.email,
                fname: action.payload.fname,
                lname: action.payload.lname,
                level: action.payload.level,
                token: action.payload.token,
                user_id: action.payload.user_id,
                company_ids: action.payload.company_ids,
                group: action.payload.group,
                ag_contact: action.payload.ag_contact,
                company_logos: action.payload.company_logos,
                first_login: action.payload.first_login
            });
        case CLEAR_PROPS:
            return Object.assign({}, state, {
                isLoggedIn: false,
                email: "",
                fname: "",
                lname: "",
                level: "",
                token: "",
                user_id: "",
                company_ids: "",
                group: "",
                ag_contact: {},
                company_logo: []
            });
        case UPDATE_USER_LEVEL:
            return Object.assign({}, state, {
                level: action.payload.level,
                fname: action.payload.fname,
                lname: action.payload.lname,
                first_login:  action.payload.firstLogin
            });
        default:
            return state;
    }
}
