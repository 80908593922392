import React from "react";
import PageHeader from "../../components/PageHeader";
import Step1 from "./RegistrationSteps/Step1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Step2 from "./RegistrationSteps/Step2";
import Step3 from "./RegistrationSteps/Step3";
import { customHistory } from "../../services/appRoutes";
import Step4 from "./RegistrationSteps/Step4";
import { SearchSpinner } from "../../services/globalFunctions";
import { connect } from "react-redux";
import WelcomeVideoModal from "../../components/Jumpseat/WelcomeVideoModal";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
// import {faQuestionCircle} from "@fortawesome/pro-light-svg-icons";

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
      showLoading: true,
      welcomeModal: false,
    };
    this.onClickNext = this.onClickNext.bind(this);
    this.onClickPrevious = this.onClickPrevious.bind(this);
  }

  onClickNext(step) {
    if (step + 1 <= 5) {
      this.setState({ activeStep: step + 1 }, () => {
        if (step + 1 === 5) {
          customHistory.push({
            pathname: "/jumpseat/my-matches",
            state: { showFallback: true },
          });
        }
      });
    }
  }

  onClickPrevious(step) {
    if (step - 1 >= 1) {
      this.setState({ activeStep: step - 1 });
    }
  }

  componentDidMount() {
    if (this.props.havePns === true && this.props.isLoggedIn) {
      customHistory.push("/jumpseat/newsfeed");
      // this.setState({showLoading: false});
    }
    if (this.props.havePns === false) {
      this.setState({ showLoading: false, welcomeModal: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.havePns !== prevProps.havePns && this.props.havePns) {
      this.setState({ showLoading: false });
      customHistory.push("/jumpseat/newsfeed");
    }
    if (this.props.havePns !== prevProps.havePns && !this.props.havePns) {
      this.setState({ showLoading: false, welcomeModal: true });
    }
  }

  render() {
    return (
      <div className="page-container jumpseat-registration-container">
        <PageHeader withMargin={true} />
        <SearchSpinner showLoading={this.state.showLoading} />
        <div
          className={"container " + (this.state.showLoading ? "d-none" : "")}
        >
          <div
            className={
              "row mb-5 " +
              (this.state.activeStep === 1 || this.state.activeStep === 5
                ? "d-none"
                : "")
            }
          >
            <div
              id="pointer"
              className={"" + (this.state.activeStep === 1 ? "active" : "")}
            >
              <label>1. Welcome</label>
              <FontAwesomeIcon
                className={
                  "check-icon " +
                  (this.state.activeStep - 1 >= 1 ? "" : "d-none")
                }
                icon={faCheckCircle}
              />
            </div>
            <div
              id="pointer"
              className={"" + (this.state.activeStep === 2 ? "active" : "")}
            >
              <label>2. Searching</label>
              <FontAwesomeIcon
                className={
                  "check-icon " +
                  (this.state.activeStep - 1 >= 2 ? "" : "d-none")
                }
                icon={faCheckCircle}
              />
            </div>
            <div
              id="pointer"
              className={"" + (this.state.activeStep === 3 ? "active" : "")}
            >
              <label>3. Offering</label>
              <FontAwesomeIcon
                className={
                  "check-icon " +
                  (this.state.activeStep - 1 >= 3 ? "" : "d-none")
                }
                icon={faCheckCircle}
              />
            </div>
            <div
              id="pointer"
              className={"" + (this.state.activeStep === 4 ? "active" : "")}
            >
              {/* <label>4. Details</label> */}
              <label>4. Product Description</label>
              <FontAwesomeIcon
                className={
                  "check-icon " +
                  (this.state.activeStep - 1 >= 4 ? "" : "d-none")
                }
                icon={faCheckCircle}
              />
            </div>
            <div
              id="pointer"
              className={"" + (this.state.activeStep === 5 ? "active" : "")}
            >
              <label>5. Your matches</label>
              <FontAwesomeIcon
                className={
                  "check-icon " +
                  (this.state.activeStep - 1 >= 5 ? "" : "d-none")
                }
                icon={faCheckCircle}
              />
            </div>
          </div>
          <div
            className={
              "text-center ste1-container " +
              (this.state.activeStep === 1 ? "" : "d-none")
            }
          >
            <Step1 onClickNext={this.onClickNext} />
          </div>
          <div
            className={
              "text-center ste1-container " +
              (this.state.activeStep === 2 ? "" : "d-none")
            }
          >
            <Step2
              onClickNext={this.onClickNext}
              onClickPrevious={this.onClickPrevious}
            />
          </div>
          <div
            className={
              "text-center ste1-container " +
              (this.state.activeStep === 3 ? "" : "d-none")
            }
          >
            <Step3
              onClickNext={this.onClickNext}
              onClickPrevious={this.onClickPrevious}
            />
          </div>
          <div
            className={
              "text-center ste1-container " +
              (this.state.activeStep === 4 ? "" : "d-none")
            }
          >
            <Step4
              onClickNext={this.onClickNext}
              onClickPrevious={this.onClickPrevious}
            />
          </div>
        </div>
        <WelcomeVideoModal
          show={this.state.welcomeModal}
          onHide={() => this.setState({ welcomeModal: false })}
        />
        <div
          onClick={() => this.setState({ welcomeModal: true })}
          style={{
            position: "fixed",
            bottom: "30px",
            right: "30px",
            zIndex: "10",
            backgroundColor: "#343a40",
            color: "white",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0 8px 6px -6px black",
          }}
        >
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            size={"lg"}
            className="mr-3"
            icon={faQuestion}
          />
          <label style={{ cursor: "pointer" }} className="m-0">
            View intro video
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let activeUserCompanyArr = state.login.company_ids;
  let level = state.login.group;
  let group = state.login.level;
  let firstLogin = state.login.first_login;
  let havePns = state.hsCodes.haveHsCode;
  let isLoggedIn = state.login.isLoggedIn;
  return {
    token,
    activeUserCompanyArr,
    level,
    group,
    firstLogin,
    havePns,
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
