import React from "react";
import { connect } from "react-redux";
import { loginMaintenance } from "../store/actions/maintenanceAction";
import { maintenance_pw } from "../services/const";

class Maintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pw: "",
      showLogin: true,
      showError: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.pw === maintenance_pw) {
      this.props.loginMaintenance(true);
    } else {
      this.setState({ showError: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.isLoggedIn !== this.props.isLoggedIn &&
      this.props.isLoggedIn
    ) {
      this.setState({ showLogin: false });
    }
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.setState({ showLogin: false });
    }
  }

  render() {
    return (
      <div
        className={
          "MaintenanceContainer position-fixed w-100 h-100 bg-dark " +
          (this.state.showLogin ? "" : "d-none")
        }
        style={{ zIndex: "999" }}
      >
        <div className="content-wrapper text-center">
          <h3 className="text-white font-weight-light mt-5">
            <img
              className="mr-2"
              style={{ height: "80px" }}
              src={require("../assets/images/WorldRing_Footer_Logo.png")}
              alt={"..."}
            />
            <p className="mt-4">WorldRing testing environment</p>
          </h3>
          <form className="w-25 text-left box" onSubmit={this.onSubmit}>
            <div className="form-group">
              {/*<label htmlFor="exampleInputPassword1">Password</label>*/}
              <input
                autoFocus={true}
                defaultValue={this.state.pw}
                onChange={(e) => this.setState({ pw: e.target.value })}
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                required={true}
                placeholder="Password"
              />
            </div>
            <p className={this.state.showError ? "" : "d-none"}>
              <small className="text-danger">
                Username / password is wrong
              </small>
            </p>
            <button type="submit" className="btn btn-dark mt-2">
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isLoggedIn = state.maintenance.isLoggedIn;
  return {
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginMaintenance: (data) => dispatch(loginMaintenance(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
