import React from "react";
import { connect } from "react-redux";
import { userLevelColors } from "../services/globalFunctions";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

class MyProductsListItem extends React.Component {
  render() {
    const { level, item, editPnS, hideButton } = this.props;
    return (
      <div
        className={"searchresult w-100 row p-3 m-0 mb-4 position-relative box "}
        id={item.pns_id}
      >
        <div
          style={{ right: "0px", top: "0px" }}
          className={"hsCodes position-absolute "}
        >
          <div
            style={{ background: userLevelColors(level).bg }}
            className="mt-2"
          >
            <span className="text-light p-1 text-capitalize">{item.type}</span>
          </div>
        </div>
        <div
          className="col-lg-3"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              height: "115px",
              width: "auto",
              objectFit: "contain",
              maxWidth: "175px",
            }}
            src={
              item.image.data
                ? `data:image/jpeg;base64,${item.image.data}`
                : item.images[0]
                ? item.images[0].data
                  ? `data:image/jpeg;base64,${item.images[0].data}`
                  : require("../assets/brand/logo.svg")
                : require("../assets/brand/logo.svg")
            }
            className=""
            alt="..."
          />
          {/*<img src={props.logo.length > 0 ? props.logo : 'https://via.placeholder.com/350x150'} className="" alt='...' />*/}
        </div>
        <div className="col-lg-8 ml-3">
          <h4
            style={{
              maxWidth: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            className="font-weight-light"
          >
            {item.name}
          </h4>
          <p
            className="mt-0"
            style={{
              maxWidth: "100%",
              fontSize: "14px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {item.description}
          </p>
          <div className="row mt-4">
            <div className="col-lg-3 properties">
              <label className="w-100">HS Code</label>
              <label>{item.hsCode.code}</label>
            </div>
            <div className="col-lg-3 properties">
              <label className="w-100">Orign</label>
              <label>{item.country}</label>
            </div>
            {!hideButton && (
              <div className="col-lg-6 px-0 text-right">
                <Button
                  onClick={() => editPnS(item)}
                  style={{
                    background: userLevelColors(level).buttonBg,
                    color: userLevelColors(level).buttonFont,
                    borderColor: userLevelColors(level).buttonFont,
                  }}
                >
                  Edit Product Entry
                </Button>
              </div>
            )}
            {hideButton && (
              <div className="col-lg-5 px-0 text-right">
                <Link
                  to={`/product/detail/${item.pns_id}`}
                  className="btn btn-wlr"
                  style={{
                    background: userLevelColors(level).buttonBg,
                    color: userLevelColors(level).buttonFont,
                    borderColor: userLevelColors(level).buttonFont,
                  }}
                >
                  Product details
                </Link>
              </div>
            )}
            {/*<div className="col-lg-3 properties">*/}
            {/*    <label className="w-100">City</label>*/}
            {/*    <label>{props.hq_city ? props.hq_city : " "}</label>*/}
            {/*</div>*/}
            {/*<div className="col-lg-3 properties">*/}
            {/*    <label className="w-100">Contact</label>*/}
            {/*    <label>{props.main_contact}</label>*/}
            {/*</div>*/}
            {/*<div className={"col-lg-3 "} style={{zIndex: '0'}}>*/}
            {/*    <div className={"properties " + (props.showOverlay ? "" : "d-none")}>*/}
            {/*        <label className="w-100">Country</label>*/}
            {/*        <label>{props.hq_location}</label>*/}
            {/*    </div>*/}
            {/*    {determineButton(props, setSuccessModal)}*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      // <div className="product-list-item col-lg-12 my-4 d-flex py-4" style={{backgroundColor: userLevelColors(level).background, border: '1px solid', borderColor: userLevelColors(level).buttonFont, borderRadius: 6}}>
      //     <p className="px-3 font-weight-bold" style={{color: "white", position: "absolute", right: 0, top: 35, textTransform: "uppercase", background: userLevelColors(level).buttonFont}}>{item.type}</p>
      //     <div className="col-lg-4">
      //         <img style={{height: "100%", maxHeight: 260, width: "100%", objectFit: "contain"}} className="lazy" src={item.image.data ? `data:image/jpeg;base64,${item.image.data}` : item.images[0] ? item.images[0].data ? `data:image/jpeg;base64,${item.images[0].data}` : require('../assets/brand/logo.svg') : require('../assets/brand/logo.svg')} alt={item.image.filename} />
      //     </div>
      //     <div className="col-lg-8 px-4">
      //         <div className="d-flex align-items-center mt-2" style={{color: userLevelColors(level).buttonFont, gap: "15px"}}>
      //             <h1 className="m-0" style={{fontSize: 26,color: userLevelColors(level).buttonFont}}>{item.name}</h1>{/*//item.title}*/}
      //             {item.certification ? <div className="d-flex align-items-center" style={{gap: "10px"}}><FontAwesomeIcon size="2x"
      //                                                                                                icon={faAward}/> Certified
      //             </div> : ""} {/*item.certified*/}
      //         </div>
      //         <p className="mt-2" style={{fontSize: 14, color: userLevelColors(level).buttonFont}}>{!showText && item.description.length > 100 ? `${item.description.substring(0, 100)}...` : item.description }</p>
      //         <p className={item.description.length > 100 ? "" : "invisible"} style={{textDecoration: "underline", cursor: "pointer", fontSize: 14, color: userLevelColors(level).buttonFont}} onClick={() => this.setState({showText: !showText})}>{!showText ? "View more" : "View less"}</p>
      //         <div className="my-3 d-flex">
      //             <div className="d-flex align-items-center px-2 py-1" style={{width: "fit-content", color: "white",background: userLevelColors(level).buttonFont}}>
      //                 <p className="mb-0" style={{fontSize: 12}}>$</p>
      //                 <p className="mb-0 mr-1" style={{fontSize: 24}}>{item.min_price} </p>
      //                 <p className="mb-0 mx-2" style={{fontSize: 12}}> - </p>
      //                 <p className="mb-0" style={{fontSize: 12}}>$</p>
      //                 <p className="mb-0 mr-1" style={{fontSize: 24}}>{item.max_price}</p>
      //                 <p className="mb-0 ml-1" style={{fontSize: 12}}>/ Piece</p>
      //             </div>
      //         </div>
      //         <div className="d-flex mt-5 align-items-center">
      //             <div className="col-lg-2 px-0" style={{color: userLevelColors(level).buttonFont}}>
      //                 <p className="m-0" style={{fontWeight: 900, fontSize: "18px"}}>HS Code</p>
      //                 <p className="m-0" style={{fontSize: "14px"}}>{item.hsCode.code}</p>
      //             </div>
      //
      //             <div className="col-lg-5" style={{color: userLevelColors(level).buttonFont}}>
      //                 <p className="m-0" style={{fontSize: "18px", fontWeight: 900}}>Min units per order</p>
      //                 <p className="m-0" style={{fontSize: "14px"}}>{item.min_units} Piece</p>
      //             </div>

      //
      //         </div>
      //     </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => {
  let level = state.login.level;
  return {
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyProductsListItem);
