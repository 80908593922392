import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle, faArrowCircleDown, faArrowCircleUp} from "@fortawesome/free-solid-svg-icons";
import {hsCodesOffering, haveHsCode} from "../../../store/actions/hsCodeAction";
import {connect} from "react-redux";
import axios from 'axios';
import {base_url} from "../../../services/const";
import {Accordion, Card, Button} from "react-bootstrap";
import {NotificationManager} from "react-notifications";

let searchInterval;

class Step3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: [],
            hsCodeVal: '',
            hsCodes: {},
            showSearchResult: false,
            showLimitError: false
        };
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
        this.getHsCode = this.getHsCode.bind(this);
        this.onClickListItem = this.onClickListItem.bind(this);
        this.removeValue = this.removeValue.bind(this);
        this.checkIfItAlredyExists = this.checkIfItAlredyExists.bind(this);
        this.showBadges = this.showBadges.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
        this.getPnS = this.getPnS.bind(this);
        this.existsInSearching = this.existsInSearching.bind(this);
    }

    componentDidMount() {
        if(this.props.companyId[0]) {
            this.setState({companyId: this.props.companyId[0]});
        }
    }

    onChangeDropdown(e) {
        this.setState({selectedOptions: e});
    }

    getHsCode(e) {
        clearTimeout(searchInterval);
        let searchVal = e.target.value;
        this.setState({hsCodeVal: searchVal, showSearchResult: true, hsCodes: []});
        if(searchVal.length > 2) {
            searchInterval = setTimeout(() => {
                axios.request({
                    method: 'get',
                    url: `${base_url}/hscodes/search/${searchVal}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${this.props.token}`
                    }
                })
                    .then((ret) => {
                        if (ret.status === 200) {
                            this.setState({
                                hsCodes: ret.data.data
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }, 3000)
        }
        if(searchVal.length <= 0) {
            this.setState({showSearchResult: false});
        }
    }

    onClickListItem(item, hsCode) {
        let arr = this.state.selectedOptions;
        let obj = {
            id: hsCode,
            item: item
        };
        if(!this.checkIfItAlredyExists(obj, arr)) {
            if(this.existsInSearching(obj)) {
                NotificationManager.error(`You can't have same HS Code in Offering and Searching`, 'Error');
                return false;
            }
            arr.push(obj);
            this.setState({[item+"-target"]: "", [item+"-specs"]: "", showLimitError: false});
        }
        this.setState({selectedOptions: arr, showSearchResult: false, hsCodeVal: ''});
    }

    existsInSearching(obj) {
        let found = this.props.hsCodeSearching.find(e => e.id === obj.id);
        if(found) {
            return true;
        }
        return false;
    }

    removeValue(item) {
        let ne = this.state.selectedOptions.filter(function( obj ) {
            return obj.id !== item.id;
        });
        this.props.hsCodesOffering(ne);
        this.setState({selectedOptions: ne, showLimitError: false});
    }

    checkIfItAlredyExists(obj, list) {
        let i;
        for (i = 0; i < list.length; i++) {
            if (list[i].id === obj.id) {
                return true;
            }
        }

        return false;
    }

    showBadges() {
        return this.state.selectedOptions.map((item, i) => {
            return (
                <h4 key={i} className="ml-3">
                    <p className="badge badge-secondary text-left">
                        {(item.item.length > 50) ? item.item.substr(0, 50-1) + '...' : item.item}
                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2" onClick={this.removeValue.bind(null, item)}  />
                    </p>
                </h4>
            )
        })
    }

    onClickNext() {
        if(this.props.hsCodeSearching.length > 0 || this.state.selectedOptions.length > 0) {
            this.state.selectedOptions.map((item) => {
                // console.log(this.state[item.item+"-specs"], this.state[item.item+"-target"]);
            });
            this.props.hsCodesOffering(this.state.selectedOptions);
            this.props.onClickNext(3);
        } else {
            NotificationManager.error('Please select at least 1 HS Code', 'Error');
        }
    }

    getPnS() {
        axios.request({
            method: 'get',
            url: `${base_url}/company/pns/${this.state.companyId}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        })
            .then((ret) => {
                if (ret.status === 200 && ret.data.data) {
                    if(ret.data.data.length > 0) {
                        let arr = [];
                        ret.data.data.map((item) => {
                            if(item.type === 'offer') {
                                if(item.hsCode.code) {
                                    let obj = {
                                        id: item.hsCode.code,
                                        item: item.hsCode.code + ' - ' + item.hsCode.description
                                    };
                                    arr.push(obj);
                                }
                            }
                        });
                        this.props.haveHsCode(true);
                        this.setState({selectedOptions: arr}, () => this.props.hsCodesOffering(arr));
                    } else {
                        this.props.havePns === true ? void 0 : this.props.haveHsCode(false);
                        this.setState({selectedOptions: []});
                    }
                } else {
                    this.props.havePns === true ? void 0 : this.props.haveHsCode(false);
                    this.setState({pns: []});
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    render() {
        return (
            <div className="text-left position-relative step-wizard">
                <h4 className="font-weight-light">What product do you want to offer?</h4>
                <p>Use the search bar below, to choose the products you want us to find for you. <br />To better identify your search, we are using the international customs tariff numbers (HS-codes). You can enter the HS-code straight or search via the name of the product.</p>
                <div className="row mb-5">
                    {
                        this.showBadges()
                    }
                </div>
                <div className="form-group w-100">
                    <input value={this.state.hsCodeVal} onChange={this.getHsCode} className="form-control" placeholder="Search HS Code" />
                    <small><i>Tip: If you are looking for a wide range of products, please enter only the first 3 digits of the HS code. <br />You can always contact your local WorldRing-Agent for any assistance.</i></small>
                </div>
                <div style={Object.keys(this.state.hsCodes).length > 0 ? {maxHeight: '200px', borderRadius: '4px', borderTop: '1px solid rgba(0, 0, 0, 0.125)',  borderBottom: '1px solid rgba(0, 0, 0, 0.125)', overflow: 'scroll'} : {}} className={"position-absolute w-100 " + (this.state.showSearchResult ? "" : "d-none")}>
                    <ul className="list-group w-100">
                        {
                            Object.keys(this.state.hsCodes).length > 0 ? Object.keys(this.state.hsCodes).map((item, i) => {
                                return <li key={i} className="list-group-item" onClick={this.onClickListItem.bind(null, this.state.hsCodes[item], item)}>{this.state.hsCodes[item]}</li>
                            }) : <div className="spinner-border" role="status" style={{marginLeft: '50%'}}>
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                    </ul>
                </div>
                <input type="button" className="btn btn-light mt-5" value="Back" onClick={this.props.onClickPrevious.bind(null, 3)} />
                <input type="button" className="btn btn-dark mt-5 float-right" value="Next" onClick={this.onClickNext} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let companyId = state.login.company_ids;
    let hsCodeSearching = state.hsCodes.searching;
    let havePns = state.hsCodes.haveHsCode;
    return {
        token, companyId, hsCodeSearching, havePns
    }
};

const mapDispatchToProps = dispatch => ({
    hsCodesOffering: (data) => dispatch(hsCodesOffering(data)),
    haveHsCode: (data) => dispatch(haveHsCode(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
