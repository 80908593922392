//login action
export const LOGIN_AUTHENTICATION = 'LOGIN_AUTHENTICATION';
export const CLEAR_PROPS = "CLEAR_PROPS";
export const UPDATE_USER_LEVEL = "UPDATE_USER_LEVEL";

//hs codes
export const HS_CODE_OFFERING = 'HS_CODE_OFFERING';
export const HS_CODE_SEARCHING = 'HS_CODE_SEARCHING';
export const HAVE_HS_CODE = "HAVE_HS_CODE";

//maintenance
export const LOGIN_MAINTENANCE = "LOGIN_MAINTENANCE";

//breadcrumbs
export const UPDATE_ROUTES = "UPDATE_ROUTES";
export const CLEAR_ROUTES = "CLEAR_ROUTES";
export const ACTIVE_PRODUCT = "ACTIVE_PRODUCT";
export const ACTIVE_COMPANY = "ACTIVE_COMPANY";
