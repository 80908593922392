import { combineReducers } from "redux";
import lReducer from './loginReducer';
import cReducer from './hsCodeReducer';
import mReducer from './maintenanceReducer';
import bReducer from './breadcrumbsReducer';

const rootReducer = combineReducers({
    login: lReducer,
    hsCodes: cReducer,
    maintenance: mReducer,
    breadcrumbs: bReducer
});
export default rootReducer;
