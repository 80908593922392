import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { base_url } from "../../services/const";
import { userLevelColors } from "../../services/globalFunctions";
import HsCodeModal from "./HsCodeModal";
import {
  faPencilAlt,
  faUpload,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import ProductModal from "../CompanyProfileModals/ServicesModal/ProductModal";
import { Link } from "react-router-dom";

class ClientHsCode extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      tableData: null,
      showHsCodeModal: false,
      countryForEdit: "",
      companyNameForEdit: "",
      rawData: {},
      hsCodeToEdit: null,
      editingOrNew: "new",
      myInvites: [],
    };
    this.getTableData = this.getTableData.bind(this);
    this.NoHsCodeFallback = this.NoHsCodeFallback.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.getInvites = this.getInvites.bind(this);
    this.onClickUploadPnS = this.onClickUploadPnS.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeKey } = this.props;
    if (activeKey !== prevProps.activeKey && activeKey === "hsCode") {
      this.getInvites();
    }
  }

  componentDidMount() {
    this.getTableData();
  }

  getTableData() {
    let data = {
      cols: [
        "Company",
        "S/O",
        "Entered on",
        "HSCode",
        "Product name",
        "Product Description",
        "Country",
        "",
      ],
      rows: [],
    };
    axios
      .request({
        method: "get",
        url: `${base_url}/company/pns/ag/dkpns`,
        // url: `${base_url}/agent/myclients`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200 && res.data.data.length > 0) {
          data.rows = res.data.data.map((item) => {
            return [
              item.pns_id,
              item.company_name,
              item.type,
              moment(item.timestamp_created).format("LL"),
              item.hsCode.code,
              item.name,
              item.description,
              item.country,
            ];
          });
          this.setState({ tableData: data, rawData: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getInvites() {
    axios
      .request({
        method: "get",
        url: `${base_url}/agent/myinvites`,
        // url: `${base_url}/agent/myclients`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        console.log(ret);
        this.setState({ myInvites: ret.data.data });
      })
      .catch((err) => {
        console.log(err, "looking for");
      });
  }

  NoHsCodeFallback() {
    return (
      <div
        className="text-center position-absolute w-100"
        style={{ marginTop: "45px", left: 0 }}
      >
        <p>No HSCodes registered. Register your first client's HSCode.</p>
        <input
          style={{
            background: userLevelColors(this.props.level).buttonBg,
            color: userLevelColors(this.props.level).buttonFont,
            borderColor: userLevelColors(this.props.level).buttonFont,
          }}
          type="button"
          className="btn btn-wlr mt-4"
          value="Create new Client HSCode Entry"
          onClick={() =>
            this.setState({ showHsCodeModal: true, editingOrNew: "new" })
          }
        />
        <div
          className="rightBlockAddNewProduct-download"
          style={{ margin: "2px 0 0 15px" }}
        >
          <Link to="/wr-massupload-template_V5a.xlsx" target="_blank" download>
            <FontAwesomeIcon
              className="mr-3"
              style={{ cursor: "pointer" }}
              icon={faDownload}
            />
          </Link>
        </div>
      </div>
    );
  }

  onClickEdit(item) {
    const { rawData } = this.state;
    let id = item[0];
    let found = rawData.find((e) => e.pns_id === id);
    this.setState({
      hsCodeToEdit: found,
      editingOrNew: "edit",
      showHsCodeModal: true,
      countryForEdit: item[7],
      companyNameForEdit: item[1],
    });
  }

  onClickUploadPnS(e) {
    let file = e.target.files[0];
    let params = new FormData();
    params.append("file", file);
    axios
      .request({
        method: "POST",
        url: `${base_url}/agent/pns/upload`,
        data: params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        if (ret.data.status === 200) {
          this.getTableData();
          NotificationManager.success("HSCodes uploaded successfully");
        } else {
          NotificationManager.error(
            ret.data.errors[Object.keys(ret.data.errors)[0]]
          );
        }
      })
      .catch((err) => {
        NotificationManager.error("Something went wrong, please try again.");
      });
    e.target.value = null;
  }

  render() {
    return (
      <div className="mt-5">
        <div className="d-flex justify-content-between mb-5 align-items-center">
          <p className="m-0">
            Enter and manage HSCodes on behalf of your customers and help them
            find new matches.
          </p>
          <div className="d-flex align-items-center">
            <input
              style={{
                background: userLevelColors(this.props.level).buttonFont,
                color: userLevelColors(this.props.level).buttonBg,
                borderColor: userLevelColors(this.props.level).buttonFont,
              }}
              type="button"
              className="btn btn-wlr"
              value="Create new Client HSCode Entry"
              onClick={() =>
                this.setState({ showHsCodeModal: true, editingOrNew: "new" })
              }
            />
            <input
              ref={this.ref}
              type="file"
              className="d-none"
              accept=".csv, .xls, .xlsx, .numbers"
              onChange={this.onClickUploadPnS}
            />
            <FontAwesomeIcon
              onClick={() => this.ref.current.click()}
              className="ml-3"
              style={{ cursor: "pointer" }}
              icon={faUpload}
            />
            <div
              className="rightBlockAddNewProduct-download"
              style={{ margin: "2px 0 0 15px" }}
            >
              <Link
                to="/wr-massupload-template_V5a.xlsx"
                target="_blank"
                download
              >
                <FontAwesomeIcon
                  className="mr-3"
                  style={{ cursor: "pointer" }}
                  icon={faDownload}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="box" style={{ padding: "30px", minHeight: "310px" }}>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="pl-2 m-0">Customer HSCodes</h5>
          </div>
          <table className={"table mt-4 table-borderless "}>
            <thead>
              <tr className="col-lg-12">
                {this.state.tableData
                  ? this.state.tableData.cols.map((item, i) => {
                      return (
                        <th key={i} scope="col">
                          {item}
                        </th>
                      );
                    })
                  : void 0}
              </tr>
            </thead>
            <tbody>
              {this.state.tableData
                ? this.state.tableData.rows.length > 0
                  ? this.state.tableData.rows.map((item, i) => {
                      return (
                        <tr className="col-lg-12" key={i}>
                          {item.map((el, i) => {
                            if (el === null || el === undefined) {
                              return <td key={i}></td>;
                            }
                            if (typeof el === "object") {
                              if (el) {
                                if (el.companyId && item.includes("open")) {
                                  return (
                                    <td key={i} className="text-right">
                                      <input
                                        style={{
                                          background: userLevelColors(
                                            this.props.level
                                          ).buttonBg,
                                          color: userLevelColors(
                                            this.props.level
                                          ).buttonFont,
                                          borderColor: userLevelColors(
                                            this.props.level
                                          ).buttonFont,
                                          marginRight: "22px",
                                        }}
                                        key={i}
                                        type="button"
                                        value="Resend Invitation"
                                        onClick={() =>
                                          this.onClickResendInvite(el.companyId)
                                        }
                                        className="btn btn-wlr"
                                      />
                                    </td>
                                  );
                                }
                              }
                            } else {
                              if (i !== 0) {
                                return <td key={i}>{el}</td>;
                              }
                            }
                          })}
                          <td>
                            <FontAwesomeIcon
                              style={{ cursor: "pointer" }}
                              onClick={this.onClickEdit.bind(null, item)}
                              icon={faPencilAlt}
                            />
                          </td>
                        </tr>
                      );
                    })
                  : this.NoHsCodeFallback()
                : this.NoHsCodeFallback()}
            </tbody>
          </table>
        </div>
        <ProductModal
          clientHSCodeModal={true}
          // countryHS={}
          isEditingItem={this.state.editingOrNew === "edit"}
          show={this.state.showHsCodeModal}
          onHide={() => this.setState({ showHsCodeModal: false })}
          companyId={this.props.companyIds[0]}
          getPnS={this.getTableData}
          itemToEdit={this.state.hsCodeToEdit}
          clientsHsCode={true}
          allClients={this.state.myInvites}
          getAllClients={this.getInvites}
          countryForEdit={this.state.countryForEdit}
          companyNameForEdit={this.state.companyNameForEdit}
        />
        {/*<HsCodeModal*/}
        {/*    getAllClients={this.getInvites}*/}
        {/*    editingOrNew={this.state.editingOrNew}*/}
        {/*    hsCodeToEdit={this.state.hsCodeToEdit}*/}
        {/*    allClients={this.state.myInvites}*/}
        {/*    onHide={() => this.setState({showHsCodeModal: false})}*/}
        {/*    show={this.state.showHsCodeModal}*/}
        {/*    getData={this.getTableData}*/}
        {/*/>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let companyIds = state.login.company_ids;
  let level = state.login.level;
  return {
    token,
    companyIds,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ClientHsCode);
