import React from "react";
import {connect} from "react-redux";
import 'react-notifications/lib/notifications.css';
import PayPalBtn from "../../components/Payment/PayPalBtn";
import MembershipPackages from "../../components/MembershipPackages";
import {NotificationManager} from "react-notifications";
import {Link, withRouter} from "react-router-dom";
import BankTransferModal from "../../components/Payment/BankTransferModal";
import {userLevelColors} from "../../services/globalFunctions";

class PaymentPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPayPalLoading: false,
            amount: this.props.location.state ? this.props.location.state.amount : null,
            tariff: this.props.location.state ? this.props.location.state.tariff : null,
            showMembershipModal: false,
            showBankModal: false
        };
        this.showModal = this.showModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
    }

    showModal() {
        this.setState({showMembershipModal: true});
    }

    componentDidMount() {
        if(!this.state.amount && !this.state.tariff) {
            this.showModal();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.state !== this.props.location.state && this.props.location.state) {
            if(this.props.location.state.amount) {
                this.setState({
                    amount: this.props.location.state.amount,
                    tariff: this.props.location.state.tariff
                }, () => this.onHideModal());
            }
        }
    }

    onHideModal() {
        this.setState({showMembershipModal: false});
    }

    render() {
        return (
            <div className="page-container PaymentPageContainer">
                <div className={"page-header mb-5"}>
                    <div className="form-group col-5 position-absolute row"
                         style={{marginLeft: '50%', transform: 'translate(-50%)', top: '250px'}}>
                    </div>
                </div>
                <div className="container text-center">
                    <h2 className={"font-weight-light "}>{this.props.level === 'dk-jumpseat' ? "Thank you for joining WorldRing" : "Choose your preferred Payment Method"}</h2>
                    <p className={"mt-3 " + (this.props.level === 'dk-jumpseat' ? "" : "d-none")}>Choose your preferred Payment Method</p>
                    <div className="row col-lg-12 mt-4">
                        <div className="col box mr-5">
                            <div className="payment-card">
                                <h3>Bank Transfer</h3>
                                <img
                                    src={require('../../assets/images/BankTransfer.png')}
                                    alt={'...'}
                                    style={{
                                        width: '250px',
                                        marginLeft: '50%',
                                        transform: 'translate(-50%)'
                                    }}
                                />
                                <p className="mb-5">Enter your invoice details and simply pay by bank transfer.</p>
                                <input style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}} onClick={() => this.setState({showBankModal: true})} type="button" className="btn btn-wlr w-100" value="Bank Transfer"/>
                            </div>
                        </div>
                        <div className="col box">
                            <div className="payment-card">
                                <h3>PayPal / Credit Card</h3>
                                <p className="my-5">Pay with Credit Card or PayPal. You will be
                                    redirected to your full account right after successfully completing the payment.</p>
                                <PayPalBtn
                                    amount={this.state.amount}
                                    description={this.state.tariff}
                                    token={this.props.token}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="overlay" className={this.state.showPayPalLoading ? "" : "d-none"}></div>
                <div className={"spinner-border text-primary " + (this.state.showPayPalLoading ? "spinner" : "d-none")}
                     role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <MembershipPackages
                    show={this.state.showMembershipModal}
                    onHide={this.onHideModal}
                />
                <BankTransferModal
                    show={this.state.showBankModal}
                    onHide={() => this.setState({showBankModal: false})}
                    amount={this.state.amount}
                    description={this.state.tariff}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let level = state.login.level;

    return {token, level};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
