import React from "react";
import { connect } from "react-redux";
import { authenticateUser } from "../store/actions/loginAction";
import { Link } from "react-router-dom";
import { JsEncrypt } from "../services/globalFunctions";
import { customHistory } from "../services/appRoutes";
import PublicContactUs from "../components/PublicContactUs";
import axios from "axios";
import { base_url } from "../services/const";
import Slider from "react-slick";
import { ButtonSpinner } from "./../services/globalFunctions";
import { NotificationManager } from "react-notifications";
import TOCPage from "../components/TOCPage";
import $ from "jquery";

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  slickPlay: true,
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showError: false,
      showContactUsModal: false,
      slideShowImages: [
        "slide1.jpg",
        "slide2.jpg",
        "slide3.jpg",
        "slide4.jpg",
        "slide5.jpg",
        "slide6.jpg",
        "slide7.jpg",
        "slide8.jpg",
        "slide9.jpg",
        "slide10.jpg",
        "slide11.jpg",
        "slide12.jpg",
        "slide13.jpg",
        // 'slide14.jpg'
      ],
      showLoading: false,
      emailSent: false,
      showTOCPage: false,
      loginRes: null,
    };

    this.onClickLogin = this.onClickLogin.bind(this);
    this.onClickForgotPassword = this.onClickForgotPassword.bind(this);
    this.onClickAccept = this.onClickAccept.bind(this);
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      const { companyIds, userLevel } = this.props;
      if (userLevel === "dk-jumpseat") {
        customHistory.push(`/newsfeed`);
      } else {
        customHistory.push(`/company-profile/user/${companyIds[0]}`);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.isLoggedIn !== prevProps.isLoggedIn &&
      this.props.isLoggedIn
    ) {
      customHistory.push(`/company-profile/user/${this.props.companyIds[0]}`);
    }
  }

  onClickLogin(e) {
    e.preventDefault();
    if (this.state.username.length > 0 && this.state.password.length > 0) {
      let enc = new JsEncrypt();
      let params = new URLSearchParams();
      params.append("email", this.state.username);
      params.append("pw", enc.encode(this.state.password));
      this.setState({ showError: false, showLoading: true });
      axios
        .request({
          method: "post",
          url: `${base_url}/login`,
          data: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            if (res.data.first_login) {
              this.setState(
                { showTOCPage: true, loginRes: res.data.data },
                () => {
                  $("html, body").css("margin", 0);
                  $("html, body").css("height", "100%");
                  $("html, body").css("overflow", "hidden");
                }
              );
            } else {
              this.setState({ showLoading: false }, () =>
                this.props.authenticateUser(res.data.data)
              );
            }
          } else {
            this.setState({ showError: true, showLoading: false });
            NotificationManager.error("Username and / or Password incorrect");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({ showError: true });
    }
  }

  onClickForgotPassword() {
    if (this.state.username) {
      let params = new URLSearchParams();
      params.append("email", this.state.username);
      axios
        .request({
          method: "post",
          url: `${base_url}/user/requestPassword`,
          data: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((ret) => {
          if (ret.status === 200 && ret.data.status === 200) {
            this.setState({ emailSent: true, showError: false });
          } else {
            NotificationManager.error("This email address does not exist.");
          }
        })
        .catch((err) => {
          NotificationManager.error(
            "Something went wrong. Please try again.",
            "Error"
          );
        });
    } else {
      NotificationManager.error("Enter you email", "Error");
    }
  }

  onClickAccept() {
    this.setState({ showTOCPage: false });
    $("html, body").css("margin", "");
    $("html, body").css("height", "");
    $("html, body").css("overflow", "");
    this.props.authenticateUser(this.state.loginRes);
  }

  render() {
    return (
      <div className="login-container page-container loginPage">
        <div className="slide-container mb-4">
          <img
            className={`wlr-logo logo-expanded`}
            style={{ zIndex: "10", left: 100 }}
            src={require("../assets/brand/logo.svg")}
            alt="brand"
          />
          <Slider {...settings}>
            {this.state.slideShowImages.map((item, i) => {
              return (
                <div className="each-slide" key={i}>
                  <div className="image-container page-header">
                    <img
                      className="lazy"
                      src={require(`../assets/images/${item}`)}
                      alt={i}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="container text-center">
          <div className="autorize  mt-4">
            <div className="leftBlock w-50">
              <h1 className="wlr-title">
                Welcome to the WorldRing - Marketplace
              </h1>

              <p className="text-left mt-4">
                The WorldRing - Marketplace is a product of the{" "}
                <a href="https://worldring.org">WorldRing </a>
                business network.
                <br />
                It is the central place, where businesses, people and
                opportunities get linked with each other. If you like to know
                more, please visit
                <a href="https://worldring.org"> worldring.org </a>
                <br />
                <br />
                If you do not yet have a login and if you are interested to
                learn more about that unique network and the possibilities that
                it offers to you, please{" "}
                <span
                  className="text-uppercase text-primary"
                  onClick={() => this.setState({ showContactUsModal: true })}
                >
                  <u style={{ cursor: "pointer" }}>GET IN TOUCH</u>
                </span>{" "}
                with us.
                <br />
                <br />
                If you already have your login details, please proceed with
                login below.
              </p>
            </div>
            <form
              className="p-4 card text-left bg-light border-0 box"
              style={{ width: "25rem" }}
            >
              <label>MEMBER LOGIN</label>
              <div className="floating-fields">
                <div className="field">
                  <input
                    type="text"
                    defaultValue={this.state.username}
                    onChange={(e) => {
                      this.setState({ username: e.target.value });
                    }}
                    name="username"
                    id="username"
                    required
                  />
                  <label htmlFor="username">Username</label>
                </div>
                <div className="field">
                  <input
                    type="password"
                    defaultValue={this.state.password}
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                    name="password"
                    id="password"
                    required
                  />
                  <label htmlFor="password">Password</label>
                </div>
              </div>
              <small
                className={
                  "text-success " + (this.state.emailSent ? "" : "d-none")
                }
              >
                An email has been sent to {this.state.username} address to reset
                the password. Please check.
              </small>
              <small
                className={
                  "text-danger " + (this.state.showError ? "" : "d-none")
                }
              >
                Username / password combination invalid
              </small>
              <div className="row">
                <div className="col-6 mt-2 text-left">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={this.onClickForgotPassword}
                    className="forgot-password text-dark"
                  >
                    Forgot Password?
                  </span>
                </div>
                <div className="col-6 text-right">
                  <button
                    disabled={this.state.showLoading}
                    onClick={this.onClickLogin}
                    className="btn btn-wlr"
                  >
                    <span className={this.state.showLoading ? "d-none" : ""}>
                      Login
                    </span>
                    <ButtonSpinner showLoading={this.state.showLoading} />
                  </button>
                </div>
              </div>
            </form>
          </div>

          {/*<h3 className="font-weight-lighter mb-0">Linking Expertise and Market Knowledge globally.</h3>*/}
          {/* <hr className="my-5 col-9" /> */}
        </div>
        <TOCPage
          show={this.state.showTOCPage}
          onClickAccept={this.onClickAccept}
          onHide={() =>
            this.setState({ showTOCPage: false }, () => {
              $("html, body").css("margin", "");
              $("html, body").css("height", "");
              $("html, body").css("overflow", "");
            })
          }
        />
        <PublicContactUs
          show={this.state.showContactUsModal}
          onHide={() => this.setState({ showContactUsModal: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isLoggedIn = state.login.isLoggedIn;
  let companyIds = state.login.company_ids;
  let firstLogin = state.login.first_login;
  let userLevel = state.login.level;
  return {
    isLoggedIn,
    companyIds,
    firstLogin,
    userLevel,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (data) => dispatch(authenticateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
