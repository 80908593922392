import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { base_url } from "../services/const";
import { Button, Spinner } from "react-bootstrap";
import { ButtonSpinner, userLevelColors } from "../services/globalFunctions";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAward,
  faEnvelope,
  faFile,
  faHandshake,
  faMobileAlt,
  faPen,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FooterCard } from "../components/FooterCard";
import { activeProduct } from "../store/actions/breadcrumbsAction";
import { Link } from "react-router-dom";

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  slickPlay: true,
  arrows: true,
};

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      data: null,
      isLoading: true,
      companyData: {},
      icon: "",
    };
    this.getProduct = this.getProduct.bind(this);
    this.getCompanyDetails = this.getCompanyDetails.bind(this);
    this.getData = this.getData.bind(this);
  }

  async componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.getProduct();
    await this.getCompanyDetails();
    this.setState({ isLoading: false });
  }

  getCompanyDetails() {
    return new Promise((resolve, reject) => {
      const { data } = this.state;
      axios
        .request({
          method: "GET",
          url: `${base_url}/company/${data.company.company_id}`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${this.props.token}`,
          },
        })
        .then((ret) => {
          console.log(ret.data.data);
          if (ret.data.status === 200 && ret.data.data) {
            this.setState({
              companyData: ret.data.data,
              icon: userLevelColors(ret.data.data.user_level).icon,
            });
          }
          resolve();
        })
        .catch((err) => resolve());
    });
  }

  getProduct() {
    return new Promise((resolve, reject) => {
      const { token } = this.props;
      const { id } = this.state;
      axios
        .request({
          method: "get",
          url: `${base_url}/company/pns/details/${id}`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((ret) => {
          if (ret.data.status === 200 && ret.data.data) {
            this.setState({ data: ret.data.data });
            this.props.activeProduct(ret.data.data.name);
          }
          resolve();
        })
        .catch((err) => resolve());
    });
  }

  render() {
    const { isLoading, data, companyData, icon } = this.state;
    const { level } = this.props;
    return (
      <div className="page-container AdminPageContainer ProductDetailsPage CompanyProfile">
        <div className="page-header"></div>
        {!isLoading && data ? (
          <div className="container mt-5 pt-4">
            <div className="row">
              <div className="col-lg-4">
                <div className="slide-container mb-5">
                  {data.images.length ? (
                    <Slider {...settings}>
                      {data.images.map((img, i) => {
                        return (
                          <div className="each-slide" key={i}>
                            <div
                              className="image-container page-header"
                              style={{ background: "#fff" }}
                            >
                              <img
                                style={{
                                  height: "100%",
                                  width: "auto",
                                  objectFit: "contian",
                                }}
                                className="lazy"
                                src={`data:image/jpeg;base64,${img.data}`}
                                alt={img.file_name}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <img
                        style={{
                          maxHeight: "264px",
                          margin: "0 auto",
                        }}
                        className="lazy"
                        src={require("../assets/brand/logo.svg")}
                        alt={""}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-8">
                <div className="box">
                  <div className="d-flex pl-4 pt-4">
                    <div className="col-lg-8">
                      <div className="d-flex">
                        <h4>{data.name}</h4>
                        {data.certification ? (
                          <div
                            className="d-flex align-items-center px-3"
                            style={{ gap: "10px" }}
                          >
                            <FontAwesomeIcon size="2x" icon={faAward} />{" "}
                            Certified
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <h6
                        style={{
                          width: "fit-content",
                          color: "white",
                          background: "black",
                        }}
                        className="p-2"
                      >
                        Listing #: {data.num}
                      </h6>
                      {data.certification_description && (
                        <p>{data.certification_description}</p>
                      )}
                    </div>
                    <div className="col-lg-4">
                      <p
                        className="p-2 text-uppercase"
                        style={{
                          //background: userLevelColors(companyData.user_level).buttonFont,
                          background: "gray",
                          color: userLevelColors(companyData.user_level)
                            .buttonBg,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {data.type}
                      </p>
                      <div>
                        <p className="font-weight-bold mb-0">Origin</p>
                        <p
                          style={{
                            maxWidth: "120px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {data.company.country || "Global"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-4 d-flex">
                    <div className="col-lg-6">
                      <div>
                        <div className="d-flex justify-content-between py-3">
                          <p className="m-0 font-weight-bold">HS Code</p>
                          <p className="m-0">{data.hs_code}</p>
                        </div>
                        {companyData.user_level === "dk-tmp" ||
                        companyData.user_level === "dk-jumpseat" ? (
                          !!data.incoterm && (
                            <div className="d-flex justify-content-between py-3">
                              <p className="m-0 font-weight-bold">Incoterm</p>
                              <p className="m-0">{data.incoterm}</p>
                            </div>
                          )
                        ) : (
                          <div className="d-flex justify-content-between py-3">
                            <p className="m-0 font-weight-bold">Incoterm</p>
                            <p className="m-0">{data.incoterm}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="py-3 d-flex justify-content-center">
                        <Button
                          style={{
                            background: userLevelColors(level).buttonBg,
                            color: userLevelColors(level).buttonFont,
                            borderColor: userLevelColors(level).buttonFont,
                          }}
                          onClick={() =>
                            companyData.user_level === "ag-member"
                              ? (window.location.href = `mailto:info@worldring.org`)
                              : companyData.user_level === "dk-tmp" ||
                                companyData.user_level === "dk-jumpseat"
                              ? (window.location.href = `mailto:${companyData.ag_contact.email}`)
                              : (window.location.href = `mailto:${
                                  companyData.company_main_contact_email ||
                                  companyData.ag_contact.email ||
                                  "info@worldring.org"
                                }`)
                          }
                          className="mr-4 px-2"
                          variant="secondary"
                        >
                          {data.type === "search"
                            ? "Contact Buyer"
                            : "Contact Seller"}
                        </Button>
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                      <div>
                        <div className="d-flex justify-content-between py-3">
                          <p className="m-0 font-weight-bold">
                            Min price per unit (USD)
                          </p>
                          <p className="m-0">{data.min_price}</p>
                        </div>
                        <div className="d-flex justify-content-between py-3">
                          <p className="m-0 font-weight-bold">
                            Max price per unit (USD)
                          </p>
                          <p className="m-0">{data.max_price}</p>
                        </div>
                        <div className="d-flex justify-content-between py-3">
                          <p className="m-0 font-weight-bold">
                            Min units per order
                          </p>
                          <p className="m-0">{data.min_units}</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-8">
                <h4 className="mb-4">Product Description</h4>
                <div className="p-3 box">
                  <p>{data.description}</p>
                  {data.seasonality_description && (
                    <p className="mt-5">
                      <strong>Seasonality description: </strong>
                      {data.seasonality_description}
                    </p>
                  )}
                  {data.mode_of_production && (
                    <p className="mt-5">
                      <strong>Mode of production: </strong>
                      {data.mode_of_production}
                    </p>
                  )}
                </div>
                {data.target_group && (
                  <>
                    <h4 className="mb-4 mt-4">Target Group</h4>
                    <div className="p-3 box">
                      <p>{data.target_group || "No info"}</p>
                    </div>
                  </>
                )}

                {data.specification && (
                  <>
                    <h4 className="mb-4 mt-4">Specification</h4>
                    <div className="p-3 box">
                      <p>{data.specification || "No info"}</p>
                    </div>
                  </>
                )}
                {/* <div className="mt-4 d-flex box">
                  <div className="col-lg-6">
                    <div>
                      <div className="d-flex justify-content-between py-3">
                        <p className="m-0 font-weight-bold">Dimensions</p>
                        <p className="m-0">{data.dimensions}</p>
                      </div>
                      <div className="d-flex justify-content-between py-3">
                        <p className="m-0 font-weight-bold">Color</p>
                        <p className="m-0">{data.colour}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div>
                      <div className="d-flex justify-content-between py-3">
                        <p className="m-0 font-weight-bold">Capacity</p>
                        <p className="m-0">{data.capacity}</p>
                      </div>
                      <div className="d-flex justify-content-between py-3">
                        <p className="m-0 font-weight-bold">Weight</p>
                        <p className="m-0">{data.weight} KG</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <FooterCard />
              </div>
              <div className="col-lg-4">
                <h4 className="mb-4 px-3">
                  {companyData.user_level === "dk-tmp" ||
                  companyData.user_level === "dk-jumpseat"
                    ? "Request Contact Details"
                    : data.type === "search"
                    ? "Buyer Profile"
                    : "Seller profile"}
                </h4>
                <div className="col-lg-12 company-profile">
                  <div
                    className="box"
                    style={{
                      background: userLevelColors(companyData.user_level).bg,
                      //background: "gray",
                    }}
                  >
                    <span
                      className="py-2 d-flex align-items-center justify-content-center text-uppercase font-weight-light w-100 text-right"
                      style={{ fontSize: "18px" }}
                    >
                      <img
                        height={50}
                        src={icon ? require(`../assets/userIcons/${icon}`) : ""}
                        alt={"..."}
                      />
                      <span
                        className="pl-2"
                        style={{
                          color: userLevelColors(companyData.user_level).font,
                        }}
                      >
                        {userLevelColors(companyData.user_level).label ===
                        "Temp"
                          ? "Jumpseat"
                          : userLevelColors(companyData.user_level).label}
                      </span>
                    </span>
                  </div>

                  {companyData.user_level === "dk-tmp" ||
                  companyData.user_level === "dk-jumpseat" ? (
                    <div className="company-profile-image-box box">
                      <div className="company-image-box">
                        <Link
                          to={`/company-profile/${companyData.ag_contact.company_id}`}
                        >
                          <img
                            src={
                              companyData.ag_contact.logo.data
                                ? `data:image/jpeg;base64,${companyData.ag_contact.logo.data}`
                                : require("../assets/brand/logo.svg")
                            }
                            className="company-profile-image"
                            alt="..."
                          />
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="company-profile-image-box box">
                      <div className="company-image-box">
                        <Link
                          to={`/company-profile/${companyData.company_id}`}
                          // styles={{ justifyContent: "center" }}
                        >
                          <img
                            src={
                              companyData.company_logo.data
                                ? `data:image/jpeg;base64,${companyData.company_logo.data}`
                                : require("../assets/brand/logo.svg")
                            }
                            className="company-profile-image"
                            // styles={{ margin: "0 auto" }}
                            alt="..."
                          />
                        </Link>
                      </div>
                    </div>
                  )}
                  {companyData.user_level === "dk-tmp" ||
                  companyData.user_level === "dk-jumpseat" ? (
                    <></>
                  ) : (
                    <div
                      className="company-profile-box box"
                      style={{ marginTop: 30 }}
                    >
                      <div className="company-credentials">
                        <h3 className="company-profile-title">
                          {companyData.company_name}
                        </h3>
                        <p className="company-profile-location">
                          {companyData.company_hq_city},{" "}
                          {companyData.company_hq_country}
                        </p>
                        <hr className="seperator" />
                        <p className="subsection text-uppercase">Headquarter</p>
                        <div>
                          <p className="company-address-list">
                            {companyData.company_hq_street},{" "}
                            {companyData.company_hq_postcode}
                          </p>
                          <p className="company-address-list">
                            {companyData.company_hq_city},{" "}
                            {companyData.company_hq_country}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {companyData.user_level === "dk-tmp" ||
                  companyData.user_level === "dk-jumpseat" ? (
                    <div
                      className="company-profile-box box"
                      style={{ paddingTop: "15px", marginTop: 30 }}
                    >
                      <div className="company-contact">
                        <p className="subsection text-uppercase">
                          Main contact
                        </p>
                        <div className="row">
                          <div className="col-lg-4">
                            <img
                              style={{
                                width: "100%",
                              }}
                              src={
                                companyData.ag_contact.contact_image.data
                                  ? `data:image/jpeg;base64,${companyData.ag_contact.contact_image.data}`
                                  : require("../assets/brand/logo.svg")
                              }
                              alt="..."
                            />
                            {/* <img
                              style={{
                                width: "100%",
                              }}
                              src={require("../assets/brand/logo.svg")}
                              alt="..."
                            /> */}
                          </div>
                          <div className="company-contact-list col-lg-7">
                            <p className="company-contact-list-name">
                              <span
                                className={
                                  companyData.ag_contact.title ? "" : "d-none"
                                }
                              >
                                {companyData.ag_contact.title}
                              </span>
                              {companyData.ag_contact.fname}{" "}
                              {companyData.ag_contact.lname}
                            </p>

                            <p
                              className={
                                "font-weight-light " +
                                (companyData.ag_contact.position
                                  ? ""
                                  : "d-none")
                              }
                            >
                              {companyData.ag_contact.position}
                            </p>

                            <p
                              className={
                                "company-contact-list-phone " +
                                (companyData.ag_contact.phone ? "" : "d-none")
                              }
                              style={{ fontSize: "14px" }}
                            >
                              <FontAwesomeIcon
                                // style={{ marginTop: "20px" }}
                                icon={faPhone}
                                className="mr-1"
                              />{" "}
                              {companyData.ag_contact.phone}
                            </p>

                            <p
                              className={
                                "company-contact-list-phone " +
                                (companyData.ag_contact.phone ? "" : "d-none")
                              }
                              style={{ fontSize: "14px" }}
                            >
                              <FontAwesomeIcon
                                icon={faMobileAlt}
                                className="mr-2"
                              />{" "}
                              {companyData.ag_contact.phone}
                            </p>
                            {/*<a style={{cursor: 'pointer'}} onClick={() => window.location.href =`skype:${this.state.companyMobile}?call`}><p className={"company-contact-list-phone " + (this.state.companyMobile ? "" : "d-none")} style={{fontSize: "14px"}}><FontAwesomeIcon icon={faMobileAlt} style={{fontSize: '16px'}} className="mr-2" /> {this.state.companyMobile}</p></a>*/}
                            {/*<p className="company-contact-list-mobile">M. {this.state.companyMobile}</p>*/}
                            {/*<p className="company-contact-list-fax">F. {this.state.companyFax}</p>*/}
                            <a
                              onClick={() =>
                                (window.location.href = `mailto:${companyData.ag_contact.email}`)
                              }
                            >
                              <p
                                className={
                                  "company-contact-list-email d-inline-flex " +
                                  (companyData.ag_contact.email ? "" : "d-none")
                                }
                                style={{ fontSize: "14px" }}
                              >
                                <FontAwesomeIcon
                                  icon={faEnvelope}
                                  className="mr-2 mt-1"
                                />
                                <span style={{ wordBreak: "break-word" }}>
                                  {companyData.ag_contact.email}
                                </span>
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="company-profile-box box"
                      style={{ paddingTop: "15px", marginTop: 30 }}
                    >
                      <div className="company-contact">
                        <p className="subsection text-uppercase">
                          Main contact
                        </p>
                        <div className="row">
                          <div className="col-lg-6">
                            <img
                              style={{
                                marginRight: "20px",
                                maxWidth: "130px",
                                // height: "150px",
                                //objectFit: "contain",
                              }}
                              src={
                                companyData.company_main_contact_image.data
                                  ? `data:image/jpeg;base64,${companyData.company_main_contact_image.data}`
                                  : require("../assets/brand/logo.svg")
                              }
                              // src={
                              //   companyData.ag_contact.logo.data
                              //     ? `data:image/jpeg;base64,${companyData.ag_contact.logo.data}`
                              //     : require("../assets/placeholders/comp-logo-placeholder.png")
                              // }
                              alt="..."
                            />
                          </div>
                          <div className="company-contact-list col-lg-6">
                            <p className="company-contact-list-name">
                              <span
                                className={
                                  companyData.company_main_contact_title
                                    ? ""
                                    : "d-none"
                                }
                              >
                                {companyData.company_main_contact_title}
                              </span>
                              {companyData.company_main_contact_fname}{" "}
                              {companyData.company_main_contact_lname}
                            </p>
                            <p
                              className={
                                "font-weight-light " +
                                (companyData.company_main_contact_jobtitle ||
                                companyData.ag_contact.position
                                  ? ""
                                  : "d-none")
                              }
                            >
                              {companyData.company_main_contact_jobtitle ||
                                companyData.ag_contact.position}
                            </p>
                            <p
                              className={
                                "company-contact-list-phone " +
                                (companyData.company_main_contact_phone
                                  ? ""
                                  : "d-none")
                              }
                              style={{ fontSize: "14px" }}
                            >
                              <FontAwesomeIcon
                                // style={{ marginTop: "15px" }}
                                icon={faPhone}
                                className="mr-1"
                              />{" "}
                              {companyData.company_main_contact_phone}
                            </p>
                            <p
                              className={
                                "company-contact-list-phone " +
                                (companyData.company_main_contact_phone
                                  ? ""
                                  : "d-none")
                              }
                              style={{ fontSize: "14px" }}
                            >
                              <FontAwesomeIcon
                                icon={faMobileAlt}
                                className="mr-2"
                              />{" "}
                              {companyData.company_main_contact_phone}
                            </p>
                            {/*<a style={{cursor: 'pointer'}} onClick={() => window.location.href =`skype:${this.state.companyMobile}?call`}><p className={"company-contact-list-phone " + (this.state.companyMobile ? "" : "d-none")} style={{fontSize: "14px"}}><FontAwesomeIcon icon={faMobileAlt} style={{fontSize: '16px'}} className="mr-2" /> {this.state.companyMobile}</p></a>*/}
                            {/*<p className="company-contact-list-mobile">M. {this.state.companyMobile}</p>*/}
                            {/*<p className="company-contact-list-fax">F. {this.state.companyFax}</p>*/}
                            <p
                              className={
                                "company-contact-list-email d-inline-flex " +
                                (companyData.company_main_contact_email
                                  ? ""
                                  : "d-none")
                              }
                              style={{ fontSize: "14px" }}
                            >
                              <a
                                onClick={() =>
                                  (window.location.href = `mailto:${companyData.company_main_contact_email}`)
                                }
                              >
                                {companyData.company_main_contact_email && (
                                  <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className="mr-2 mt-1"
                                  />
                                )}

                                <span style={{ wordBreak: "break-word" }}>
                                  {companyData.company_main_contact_email}
                                </span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {(companyData.user_level === "dk-economy" ||
                    companyData.user_level === "dk-first_class" ||
                    companyData.user_level === "dk-business") && (
                    <div
                      className="company-profile-box box"
                      style={{ paddingTop: "15px", marginTop: 30 }}
                    >
                      <div className="company-contact">
                        <p className="subsection text-uppercase">
                          AGENT IN CHARGE
                        </p>
                        <div className="row">
                          <div className="col-lg-12 d-flex imgCenter">
                            {companyData.user_level === "dk-tmp" ||
                            companyData.user_level === "dk-jumpseat" ? (
                              <Link
                                to={`/company-profile/${companyData.company_id}`}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    maxHeight: "150px",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    companyData.ag_contact.logo.data
                                      ? `data:image/jpeg;base64,${companyData.ag_contact.logo.data}`
                                      : require("../assets/brand/logo.svg")
                                  }
                                  alt="..."
                                />
                              </Link>
                            ) : (
                              <Link
                                to={`/company-profile/${companyData.ag_contact.company_id}`}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    maxHeight: "150px",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    companyData.ag_contact.logo.data
                                      ? `data:image/jpeg;base64,${companyData.ag_contact.logo.data}`
                                      : require("../assets/brand/logo.svg")
                                  }
                                  alt="..."
                                />
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {data.pdf.data && (
                    <div className="box" style={{ marginTop: 30 }}>
                      <div
                        className="p-5 text-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let pdfWindow = window.open("");
                          pdfWindow.document.write(
                            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                              encodeURI(data.pdf.data) +
                              "'></iframe>"
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faFile} size="3x" />
                        <p className="mt-3">{data.pdf.filename}</p>
                      </div>
                    </div>
                  )}
                  <div className="box" style={{ marginTop: 30 }}>
                    <div className="p-5 text-center">
                      <FontAwesomeIcon icon={faHandshake} size="3x" />
                      <h4 className="mb-4 mt-3">Ready to do a request?</h4>
                      <Button
                        onClick={() =>
                          companyData.user_level === "ag-member"
                            ? (window.location.href = `mailto:info@worldring.org`)
                            : companyData.user_level === "dk-tmp" ||
                              companyData.user_level === "dk-jumpseat"
                            ? (window.location.href = `mailto:${companyData.ag_contact.email}`)
                            : (window.location.href = `mailto:${
                                companyData.company_main_contact_email ||
                                companyData.ag_contact.email ||
                                "info@worldring.org"
                              }`)
                        }
                        style={{
                          background: "black",
                          color: "white",
                        }}
                      >
                        Contact now
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container mt-5 text-center">
            <div
              style={{
                height: "3em",
                width: "3em",
                color: userLevelColors(level).bg,
              }}
              className={"spinner-border "}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({
  activeProduct: (data) => dispatch(activeProduct(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
