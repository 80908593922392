import React from "react";
import { authenticateUser } from "../../store/actions/loginAction";
import { connect } from "react-redux";
import axios from "axios";
import { base_url } from "../../services/const";
import { SearchResultListItem } from "../../components/SearchResultListItem";
import { JumpseatMatchesFallback } from "../../components/Jumpseat/JumpseatMatchesFallback";
import { JSMatchesSearchSpinner } from "../../services/globalFunctions";

class JumpseatMatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      searchVal: "",
      membershipModal: false,
      matchesLoading: false,
      pnsLoading: false,
      searchingCodes: [],
      companyId: null,
      showFallback: false,
    };
    this.getMatches = this.getMatches.bind(this);
    this.getPnS = this.getPnS.bind(this);
  }

  componentDidMount() {
    if (this.props.companyId[0]) {
      this.setState({ companyId: this.props.companyId[0] }, () =>
        this.getPnS()
      );
    }
    if (this.props.location.state && this.props.location.state.showFallback) {
      this.setState({ showFallback: true });
    }
    this.getMatches();
  }

  getMatches() {
    this.setState({ matchesLoading: true });
    axios
      .request({
        method: "get",
        url: `${base_url}/company/mymatches`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        if (ret.data.status === 200 || ret.data.status ===204) {
          this.setState({
            matches: ret.data.data,
          });
        }
        this.loadingMatchesHandler();
      })
      .catch((err) => {
        console.log(err);
        this.loadingMatchesHandler();
      });
  }

  loadingMatchesHandler () {
    return setTimeout(() => {
      this.setState({ matchesLoading: false })
    }, 3000)
  }

  loadingPNSHandler () {
    return setTimeout(() => {
      this.setState({ pnsLoading: false })
    }, 3000)
  }
  getPnS() {
    this.setState({pnsLoading: true});
    axios
      .request({
        method: "get",
        url: `${base_url}/company/pns/${this.state.companyId}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        if (ret.data.status === 200 && ret.data.data) {
          if (ret.data.data.length > 0) {
            let arr = [];
            ret.data.data.map((item) => {
              if (item.type === "search") {
                if (item.hsCode.code) {
                  let obj = {
                    id: item.hsCode.code,
                    item: item.hsCode.code + " - " + item.hsCode.description,
                  };
                  arr.push(obj);
                }
              }
            });
            this.setState({ searchingCodes: arr });
            this.loadingPNSHandler();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.loadingPNSHandler();
      });
  }

  render() {
    return (
      <div className={" page-container JumpSeatMatchesContainer"}>
        <div
          className={
            " " + (this.state && this.state.matches && this.state.matches.length > 0 ? "mb-5 page-header" : "")
          }
        >
          <div
            className="form-group col-5 position-absolute row"
            style={{
              marginLeft: "50%",
              transform: "translate(-50%)",
              top: "250px",
            }}
          ></div>
        </div>
        <JSMatchesSearchSpinner showLoading={this.state.matchesLoading && this.state.pnsLoading } />
        <div
          className={
            (this.state && this.state.matches && this.state.matches.length > 0 ? " container " : "") +
            (this.state.matchesLoading && this.state.pnsLoading ? "d-none" : "")
          }
        >
          <div className={this.state && this.state.matches && this.state.matches.length > 0 ? " container " : ""}>
            {this.state && this.state.matches && this.state.matches.length > 0 ? (
              this.state.matches.map((item, i) => {
                return (
                  <SearchResultListItem
                    user={this.props.level}
                    {...item}
                    showOverlay={true}
                    key={i}
                  />
                );
              })
            ) : this.state.showFallback ? (
              <JumpseatMatchesFallback
                showFallback={true}
                searchingHsCode={this.state.searchingCodes}
              />
            ) : (
              <JumpseatMatchesFallback
                searchingHsCode={this.state.searchingCodes}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let isLoggedIn = state.login.isLoggedIn;
  let token = state.login.token;
  let searchingHsCode = state.hsCodes.searching;
  let level = state.login.level;
  let companyId = state.login.company_ids;
  return {
    isLoggedIn,
    token,
    searchingHsCode,
    level,
    companyId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (data) => dispatch(authenticateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JumpseatMatches);
