import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFileAlt,
  faImage,
  faTimesCircle,
  faTimes,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { toBase64, userLevelColors } from "../../services/globalFunctions";
import axios from "axios";
import { base_url, incotermOptions, countryList } from "../../services/const";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { MultiSelect } from "react-multi-select-component";
import classNames from "classnames";
import Select from "react-select";

const options = countryList.map((i) => {
  return { value: i, label: i };
});

let searchInterval;

class OSFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      description: "",
      fileName: "",
      target: "",
      specifications: "",
      image: null,
      file: null,
      selectedOptions: {},
      hsCodeVal: "",
      hsCodes: {},
      showSearchResult: false,
      existingImageThumbnail: [],
      newImageThumbnail: [],
      type: props.typeAdding ? props.typeAdding : "search",
      quantity: "",
      weight: "",
      minUnits: "",
      certification: "false",
      season: "false",
      length: "",
      width: "",
      height: "",
      modeOfProduction: "",
      capacity: "",
      color: "",
      minPrice: "",
      maxPrice: "",
      incoterm: [],
      certificationDescription: "",
      seasonalityDescription: "",
      existingFile: null,
    };
    this.setDataToFields = this.setDataToFields.bind(this);
    this.getHsCode = this.getHsCode.bind(this);
    this.onClickListItem = this.onClickListItem.bind(this);
    this.removeValue = this.removeValue.bind(this);
    this.showBadges = this.showBadges.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.onClickRemoveImage = this.onClickRemoveImage.bind(this);
    this.onClickRemoveNewImage = this.onClickRemoveNewImage.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(
      "this.state.existingImageThumbnail",
      this.state.existingImageThumbnail
    );
    if (
      this.props.itemToEdit !== prevProps.itemToEdit &&
      Object.keys(this.props.itemToEdit).length > 0
    ) {
      this.setDataToFields();
    }
  }

  componentDidMount() {
    const { isEditing, itemToEdit } = this.props;

    if (isEditing && Object.keys(itemToEdit).length > 0) {
      this.setDataToFields();
    }
  }

  setDataToFields() {
    const { itemToEdit } = this.props;
    const dimension = itemToEdit.dimensions
      ? itemToEdit.dimensions.split(",")
      : [];
    const incoterms = itemToEdit.incoterm
      ? itemToEdit.incoterm
          .split(",")
          .map((item) => ({ value: item, label: item }))
      : [];
    this.props.onChangeDescription(itemToEdit.description);
    this.props.onChangeName(itemToEdit.name);
    this.props.onChangeDimension(dimension[0], "length");
    this.props.onChangeDimension(dimension[1], "width");
    this.props.onChangeDimension(dimension[2], "height");
    this.setState({
      productName: itemToEdit.name,
      target: itemToEdit.target_group,
      specifications: itemToEdit.specification,
      description: itemToEdit.description,
      existingImageThumbnail: itemToEdit.images,
      type: itemToEdit.type,
      minPrice: itemToEdit.min_price,
      maxPrice: itemToEdit.max_price,
      minUnits: itemToEdit.min_units,
      quantity: itemToEdit.quantity,
      season: itemToEdit.seasonality,
      certification: itemToEdit.certification === 1 ? "true" : "false",
      capacity: itemToEdit.capacity,
      color: itemToEdit.colour,
      modeOfProduction: itemToEdit.mode_of_production,
      length: dimension.length > 0 ? dimension[0] : "",
      width: dimension.length >= 1 ? dimension[1] : "",
      height: dimension.length >= 2 ? dimension[2] : "",
      selectedOptions: { item: `${itemToEdit.hs_code}` },
      incoterm: incoterms,
      weight: itemToEdit.weight,
      seasonalityDescription: itemToEdit.seasonality_description,
      certificationDescription: itemToEdit.certification_description,
      existingFile: itemToEdit.pdf,
      fileName: itemToEdit.pdf.filename,
    });
  }

  showBadges() {
    const { selectedOptions } = this.state;
    if (selectedOptions.item) {
      return (
        <h4 className="ml-3">
          <p className="badge badge-secondary  text-left">
            {selectedOptions.item.length > 50
              ? selectedOptions.item.substr(0, 10) + "..."
              : selectedOptions.item}
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="ml-2"
              onClick={this.removeValue.bind(null, selectedOptions)}
            />
          </p>
        </h4>
      );
    }
  }

  removeValue(item) {
    this.setState({ selectedOptions: {} });
  }

  onClickListItem(item, hsCode) {
    const { selectedOptions } = this.state;
    if (!selectedOptions.item) {
      let obj = {
        id: hsCode,
        item: item,
      };
      this.setState(
        {
          [item + "-target"]: "",
          [item + "-specs"]: "",
          selectedOptions: obj,
          showSearchResult: false,
          hsCodeVal: "",
        },
        () => {
          this.props.onChangeHsCode(obj);
        }
      );
    } else {
      NotificationManager.error("Only one HS Code per item", "Error");
    }
    this.setState({ showSearchResult: false });
  }

  getHsCode(e) {
    clearTimeout(searchInterval);
    let searchVal = e.target.value;
    this.setState({
      hsCodeVal: searchVal,
      showSearchResult: true,
      hsCodes: [],
    });
    if (searchVal.length > 2) {
      searchInterval = setTimeout(() => {
        axios
          .request({
            method: "get",
            url: `${base_url}/hscodes/search/${searchVal}`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${this.props.token}`,
            },
          })
          .then((ret) => {
            if (ret.status === 200) {
              this.setState({
                hsCodes: ret.data.data,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 3000);
    }
    if (searchVal.length <= 0) {
      this.setState({ showSearchResult: false });
    }
  }

  async onChangeImage(e) {
    if ((e.target.files[0].size / 1024 / 1024).toFixed(4) > 5) {
      NotificationManager.error("File size too large. Max size 5mb.", "Error");
    } else {
      this.setState({ image: e.target.files[0] }, () =>
        this.props.onChangeImage(this.state.image)
      );
      let logo = {
        mime: e.target.files[0].type,
        filename: e.target.files[0].name,
      };
      await toBase64(e.target.files[0]).then((res) => {
        logo.data = res;
        const { newImageThumbnail } = this.state;
        newImageThumbnail.push(logo);
        this.setState({
          newImageThumbnail: newImageThumbnail,
          showFileError: false,
        });
      });
    }
  }

  onClickRemoveImage(image) {
    const { existingImageThumbnail } = this.state;
    const filtered = existingImageThumbnail.filter(
      (e) => e.filename !== image.filename
    );
    axios.request({
      method: "DELETE",
      url: `${base_url}/company/pns/file/${image.image_id}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`,
      },
    });
    this.setState({
      existingImageThumbnail: filtered,
    });
  }

  onClickRemoveNewImage(image) {
    const { newImageThumbnail } = this.state;
    const filtered = newImageThumbnail.filter(
      (e) => e.filename !== image.filename
    );
    axios.request({
      method: "DELETE",
      url: `${base_url}/company/pns/file/${image.image_id}`,
    });
    this.setState({ newImageThumbnail: filtered });
  }

  render() {
    const { showTitleError, showDescriptionError, showHsCodeError } =
      this.props;
    return (
      <div className="row">
        <div className="mb-1 ml-0 row col-lg-12">{this.showBadges()}</div>
        <div className="form-group w-100 col-lg-12">
          <input
            value={this.state.hsCodeVal}
            onChange={this.getHsCode}
            className={classNames(
              "form-control",
              userLevelColors(this.props.level).className,
              { "input-error": showHsCodeError }
            )}
            placeholder="Search HS Code"
          />
          <small className="">
            <i>
              Tip: If you are looking for a wide range of products, please enter
              only the first 3 digits of the HS code. <br />
              You can always contact your local WorldRing-Agent for any
              assistance.
            </i>
          </small>
        </div>
        <div
          style={
            Object.keys(this.state.hsCodes).length > 0
              ? {
                  maxHeight: "200px",
                  borderRadius: "4px",
                  overflow: "scroll",
                  zIndex: "10",
                  marginTop: "80px",
                }
              : {}
          }
          className={
            "position-absolute col-lg-12 w-100 " +
            (this.state.showSearchResult ? "" : "d-none")
          }
        >
          <ul className="list-group w-100">
            {Object.keys(this.state.hsCodes).length > 0 ? (
              Object.keys(this.state.hsCodes).map((item, i) => {
                return (
                  <li
                    key={i}
                    className="list-group-item"
                    onClick={this.onClickListItem.bind(
                      null,
                      this.state.hsCodes[item],
                      item
                    )}
                  >
                    {this.state.hsCodes[item]}
                  </li>
                );
              })
            ) : (
              <div
                className="spinner-border"
                role="status"
                style={{ marginLeft: "50%" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </ul>
        </div>
        <div className="col-lg-4">
          {this.state.existingImageThumbnail.length > 0 && (
            <div>
              {this.state.existingImageThumbnail.map((item, i) => {
                return (
                  <div
                    key={item.image_id}
                    className="file-upload-content position-relative"
                    style={{
                      height: "180px",
                      width: "100%",
                      border: "2px dashed #ebebeb",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <FontAwesomeIcon
                        onClick={() => this.onClickRemoveImage(item)}
                        icon={faTimes}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: 10,
                          right: 10,
                        }}
                      />
                      <img
                        style={{ width: "165px", objectFit: "cover" }}
                        src={`data:image/jpeg;base64,${item.data}`}
                        alt={"..."}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {this.state.newImageThumbnail.length > 0 && (
            <div>
              {this.state.newImageThumbnail.map((item, i) => {
                return (
                  <div
                    key={item.data}
                    className="file-upload-content fileWithRemoving position-relative testqwe"
                    style={{
                      height: "180px",
                      width: "100%",
                      border: "2px dashed #ebebeb",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <img
                        style={{ objectFit: "cover", width: "165px" }}
                        src={`${item.data}`}
                        alt={"..."}
                      />
                    </div>
                    <div>
                      <FontAwesomeIcon
                        onClick={() => this.onClickRemoveNewImage(item)}
                        icon={faTimes}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: 10,
                          right: 10,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="file-upload-container-contact-person">
            <div className="file-upload-content">
              <div>
                <FontAwesomeIcon
                  size={"3x"}
                  icon={
                    this.state.newImageThumbnail.length > 0 ||
                    this.state.existingImageThumbnail.length > 0
                      ? faPlus
                      : faImage
                  }
                />
              </div>
              <p className="title pt-2 mb-1 ">Drag & Drop</p>
              <p className="sub-title mb-3 ">
                your product or service image here
              </p>
              {this.state.newImageThumbnail.length === 0 && (
                <p className="sub-title mt-1">max 5mb</p>
              )}
              <br />
              &nbsp;
            </div>
            <input
              style={{
                padding: "130px 0px 12px 23%",
              }}
              type="file"
              id="file-upload"
              className={
                "choose-file-input " + (this.state.image ? "" : "file-selected")
              }
              onChange={this.onChangeImage}
              accept=".png, .jpeg, .jpg"
            />
          </div>
          <div className="file-upload-container-contact-person">
            <div className="file-upload-content">
              <FontAwesomeIcon
                size={"2x"}
                icon={
                  this.state.file || this.state.fileName ? faCheck : faFileAlt
                }
              />
              <p className="title pt-2 mb-1 ">
                {this.state.file
                  ? this.state.file.name
                  : this.state.existingFile
                  ? this.state.existingFile.filename
                    ? this.state.existingFile.filename
                    : "Drag & Drop"
                  : "Drag & Drop"}
              </p>
              {!this.state.file && !this.state.fileName && (
                <p className="sub-title mb-3 ">your pdf here.</p>
              )}
              <br />
              &nbsp;
            </div>
            <input
              type="file"
              id="file-upload"
              className={
                "choose-file-input " + (this.state.file ? "" : "file-selected")
              }
              onChange={(e) =>
                this.setState(
                  { file: e.target.files[0], fileName: e.target.files[0].name },
                  () => this.props.onChangePdf(this.state.file)
                )
              }
              accept=".pdf, .csv, .xls, .xlsx"
            />
          </div>
        </div>
        <div className="col-lg-8">
          <div className="form-group">
            <select
              value={this.state.type}
              onChange={(e) =>
                this.setState({ type: e.target.value }, () =>
                  this.props.onChangeType(this.state.type)
                )
              }
              className={
                "form-control  " + userLevelColors(this.props.level).className
              }
            >
              <option value="search">Search</option>
              <option value="offer">Offer</option>
            </select>
          </div>
          <div className="form-group">
            <input
              defaultValue={this.state.productName}
              onSelect={(e) => {
                this.setState({ productName: e.currentTarget.value });
                this.props.onChangeName(e.currentTarget.value);
              }}
              onChange={(e) => {
                this.setState({ productName: e.currentTarget.value });
                this.props.onChangeName(e.currentTarget.value);
              }}
              type="text"
              className={
                `${showTitleError ? "input-error" : ""} form-control  ` +
                userLevelColors(this.props.level).className
              }
              placeholder="Product/service name"
            />
          </div>
          <div className="form-group">
            <textarea
              defaultValue={this.state.description}
              onSelect={(e) => {
                this.setState({ description: e.currentTarget.value });
                this.props.onChangeDescription(e.currentTarget.value);
              }}
              onChange={(e) => {
                this.setState({ description: e.currentTarget.value });
                this.props.onChangeDescription(e.currentTarget.value);
              }}
              rows={3}
              className={
                `${showDescriptionError ? "input-error" : ""} form-control  ` +
                userLevelColors(this.props.level).className
              }
              placeholder="Product/service description"
            />
          </div>
          {(this.state.file ||
            (this.state.existingFile && this.state.existingFile.data) ||
            this.state.image) && (
            <div className="form-group">
              <input
                defaultValue={this.state.fileName}
                onChange={(e) =>
                  this.setState({ fileName: e.target.value }, () =>
                    this.props.onChangeFileName(this.state.fileName)
                  )
                }
                type="text"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="File name"
              />
            </div>
          )}
          {this.state.type === "search" ? (
            <></>
          ) : (
            <div className="form-group">
              <input
                defaultValue={this.state.target}
                onChange={(e) =>
                  this.setState({ target: e.target.value }, () =>
                    this.props.onChangeTarget(this.state.target)
                  )
                }
                type="text"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="Target group"
              />
            </div>
          )}

          <div className="form-group">
            <input
              defaultValue={this.state.specifications}
              onChange={(e) =>
                this.setState({ specifications: e.target.value }, () =>
                  this.props.onChangeSpecs(this.state.specifications)
                )
              }
              type="text"
              className={
                "form-control  " + userLevelColors(this.props.level).className
              }
              placeholder="Specification"
            />
          </div>

          {this.props.notShowing && !this.props.isEditing && (
            <>
              <div className="d-flex form-group">
                <div className="col-lg-6 pl-0">
                  <input
                    defaultValue={this.state.minPrice}
                    onChange={(e) =>
                      this.setState({ minPrice: e.target.value }, () =>
                        this.props.onChangeMinPrice(this.state.minPrice)
                      )
                    }
                    type="number"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Min price in USD"
                  />
                </div>
                <div className="col-lg-6 pr-0">
                  <input
                    defaultValue={this.state.maxPrice}
                    onChange={(e) =>
                      this.setState({ maxPrice: e.target.value }, () =>
                        this.props.onChangeMaxPrice(this.state.maxPrice)
                      )
                    }
                    type="number"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Max price in USD"
                  />
                </div>
              </div>
              <div className="d-flex form-group">
                <div className="col-lg-4 pl-0">
                  <input
                    defaultValue={this.state.quantity}
                    onChange={(e) =>
                      this.setState({ quantity: e.target.value }, () =>
                        this.props.onChangeQuantity(this.state.quantity)
                      )
                    }
                    type="number"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Quantity"
                  />
                </div>
                <div className="col-lg-4">
                  <input
                    defaultValue={this.state.weight}
                    onChange={(e) =>
                      this.setState({ weight: e.target.value }, () =>
                        this.props.onChangeWeight(this.state.weight)
                      )
                    }
                    type="number"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Weight in KG"
                  />
                </div>
                <div className="col-lg-4 pr-0">
                  <input
                    defaultValue={this.state.minUnits}
                    onChange={(e) =>
                      this.setState({ minUnits: e.target.value }, () =>
                        this.props.onChangeMinUnits(this.state.minUnits)
                      )
                    }
                    type="number"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Min units"
                  />
                </div>
              </div>
              <div className="d-flex form-group">
                <div
                  className="form-group pl-0 col-6 mb-0 d-flex"
                  style={{ color: "rgba(0, 0, 0, 0.4)" }}
                >
                  <div
                    className="form-check-inline"
                    style={{ color: "dimgray", paddingBottom: 5 }}
                  >
                    Certification
                  </div>
                  <div className="form-check form-check-inline px-2">
                    <input
                      onChange={(e) =>
                        this.setState({ certification: e.target.value }, () =>
                          this.props.onChangeCertification(
                            this.state.certification
                          )
                        )
                      }
                      checked={this.state.certification === "true"}
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="true"
                    />
                    <label
                      className="form-check-label mt-0"
                      htmlFor="inlineRadio1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline px-2">
                    <input
                      onChange={(e) =>
                        this.setState({ certification: e.target.value }, () =>
                          this.props.onChangeCertification(
                            this.state.certification
                          )
                        )
                      }
                      checked={this.state.certification === "false"}
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="false"
                    />
                    <label
                      className="form-check-label mt-0"
                      htmlFor="inlineRadio2"
                    >
                      No
                    </label>
                  </div>
                </div>
                {this.state.certification === "true" && (
                  <div className="form-group col-lg-6 px-0">
                    <input
                      defaultValue={this.state.certificationDescription}
                      onChange={(e) =>
                        this.setState(
                          { certificationDescription: e.target.value },
                          () =>
                            this.props.onChangeCertificationDescription(
                              this.state.certificationDescription
                            )
                        )
                      }
                      type="text"
                      className={
                        "form-control  " +
                        userLevelColors(this.props.level).className
                      }
                      placeholder="Certification description"
                    />
                  </div>
                )}
                {this.state.certification !== "true" && (
                  <div
                    className="form-group pr-0 col-6 mb-0"
                    style={{ color: "rgba(0, 0, 0, 0.4)" }}
                  >
                    <div
                      className="form-check-inline"
                      style={{ color: "dimgray", paddingBottom: 5 }}
                    >
                      Seasonality
                    </div>
                    <div className="form-check form-check-inline px-2">
                      <input
                        onChange={(e) =>
                          this.setState({ season: e.target.value }, () =>
                            this.props.onChangeSeason(this.state.season)
                          )
                        }
                        checked={this.state.season === "true"}
                        className="form-check-input"
                        type="radio"
                        name="seasonRadioOption"
                        id="seasonality1"
                        value="true"
                      />
                      <label
                        className="form-check-label mt-0"
                        htmlFor="seasonality1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline px-2">
                      <input
                        onChange={(e) =>
                          this.setState({ season: e.target.value }, () =>
                            this.props.onChangeSeason(this.state.season)
                          )
                        }
                        checked={this.state.season === "false"}
                        className="form-check-input"
                        type="radio"
                        name="seasonRadioOption"
                        id="seasonality2"
                        value="false"
                      />
                      <label
                        className="form-check-label mt-0"
                        htmlFor="seasonality2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex">
                {this.state.certification === "true" && (
                  <div
                    className="form-group pr-0 col-6 mb-0 px-0 d-flex"
                    style={{ color: "rgba(0, 0, 0, 0.4)" }}
                  >
                    <div
                      className="form-check-inline"
                      style={{ color: "dimgray", paddingBottom: 5 }}
                    >
                      Seasonality
                    </div>
                    <div className="form-check form-check-inline px-2">
                      <input
                        onChange={(e) =>
                          this.setState({ season: e.target.value }, () =>
                            this.props.onChangeSeason(this.state.season)
                          )
                        }
                        checked={this.state.season === "true"}
                        className="form-check-input"
                        type="radio"
                        name="seasonRadioOption"
                        id="seasonality1"
                        value="true"
                      />
                      <label
                        className="form-check-label mt-0"
                        htmlFor="seasonality1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline px-2">
                      <input
                        onChange={(e) =>
                          this.setState({ season: e.target.value }, () =>
                            this.props.onChangeSeason(this.state.season)
                          )
                        }
                        checked={this.state.season === "false"}
                        className="form-check-input"
                        type="radio"
                        name="seasonRadioOption"
                        id="seasonality2"
                        value="false"
                      />
                      <label
                        className="form-check-label mt-0"
                        htmlFor="seasonality2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                )}
                {this.state.season === "true" && (
                  <div className="form-group col-lg-6 px-0">
                    <input
                      defaultValue={this.state.seasonalityDescription}
                      onChange={(e) =>
                        this.setState(
                          { seasonalityDescription: e.target.value },
                          () =>
                            this.props.onChangeSeasonalityDescription(
                              this.state.seasonalityDescription
                            )
                        )
                      }
                      type="text"
                      className={
                        "form-control  " +
                        userLevelColors(this.props.level).className
                      }
                      placeholder="Seasonality description"
                    />
                  </div>
                )}
              </div>

              <div className="d-flex align-items-center form-group">
                <div
                  className="form-check-inline d-flex align-items-center"
                  style={{ color: "dimgray", paddingBottom: 5 }}
                >
                  Dimension
                </div>
                <div className="col-lg-2 ">
                  <input
                    defaultValue={this.state.length}
                    onChange={(e) =>
                      this.setState({ length: e.target.value }, () =>
                        this.props.onChangeDimension(
                          this.state.length,
                          "length"
                        )
                      )
                    }
                    type="number"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="L"
                  />
                </div>
                <FontAwesomeIcon icon={faTimes} color={"dimgray"} />
                <div className="col-lg-2">
                  <input
                    defaultValue={this.state.width}
                    onChange={(e) =>
                      this.setState({ width: e.target.value }, () =>
                        this.props.onChangeDimension(this.state.width, "width")
                      )
                    }
                    type="number"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="W"
                  />
                </div>
                <FontAwesomeIcon icon={faTimes} color={"dimgray"} />
                <div className="col-lg-2 ">
                  <input
                    defaultValue={this.state.height}
                    onChange={(e) =>
                      this.setState({ height: e.target.value }, () =>
                        this.props.onChangeDimension(
                          this.state.height,
                          "height"
                        )
                      )
                    }
                    type="number"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="H"
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  defaultValue={this.state.modeOfProduction}
                  onChange={(e) =>
                    this.setState({ modeOfProduction: e.target.value }, () =>
                      this.props.onChangeModeOfProduction(
                        this.state.modeOfProduction
                      )
                    )
                  }
                  type="text"
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Describe mode of production"
                />
              </div>
              <div className="d-flex form-group">
                <div className="col-lg-6 pl-0">
                  <input
                    defaultValue={this.state.color}
                    onChange={(e) =>
                      this.setState({ color: e.target.value }, () =>
                        this.props.onChangeColor(this.state.color)
                      )
                    }
                    type="text"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Color"
                  />
                </div>
                <div className="col-lg-6 pr-0">
                  <input
                    defaultValue={this.state.capacity}
                    onChange={(e) =>
                      this.setState({ capacity: e.target.value }, () =>
                        this.props.onChangeCapacity(this.state.capacity)
                      )
                    }
                    type="text"
                    className={
                      "form-control  " +
                      userLevelColors(this.props.level).className
                    }
                    placeholder="Capacity"
                  />
                </div>
              </div>
            </>
          )}
          <div className="form-group">
            <MultiSelect
              options={incotermOptions}
              value={this.state.incoterm}
              hasSelectAll={false}
              onChange={(e) => {
                this.setState({ incoterm: e });
                this.props.onChangeIncoterm(e);
              }}
              overrideStrings={{
                selectSomeItems:
                  "Choose terms of preferred delivery (incoterm)",
              }}
            />
          </div>
          {this.props.clientHSCodeModal && (
            <div
              // styles={{ marginBottom: "15px" }}
              className={classNames("mb-3", "reacrSelectHScountry", {
                "input-error": this.props.countryForHSCodeError,
              })}
            >
              <Select
                options={[
                  {
                    label: "Country",
                    value: "",
                    isDisabled: true,
                  },
                  ...options,
                ]}
                placeholder="Country"
                onChange={(e) => this.props.onChangeCountru(e)}
                value={this.props.countryForHSCode}
                defaultValue={{ label: "Country", value: "", isDisabled: true }}
              />
            </div>
          )}
        </div>
        <hr className="seperator" style={{ width: "95%" }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OSFormComponent);
