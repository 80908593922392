import React from "react";
import { connect } from "react-redux";
import { Accordion, Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
  base_url,
  InfoTooltip,
  SpecificationToolTipText,
  TargetToolTipText,
} from "../../../services/const";
import JumpseatConfirmationModal from "../../../components/JumpseatConfirmationModal";
import OSFormComponent from "../../../components/CompanyProfileComponents/OSFormComponent";
import NewHsCodeForm from "../../../components/Jumpseat/NewHsCodeForm";
import { uploadImage, uploadPdf } from "../../../services/globalFunctions";
import { NotificationManager } from "react-notifications";

class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offeringVal: [],
      searchingVal: [],
      "search-dimension": { height: 0, width: 0, length: 0 },
      "offer-dimension": { height: 0, width: 0, length: 0 },
      showDescriptionError: false,
      showTitleError: false,
      image: [],
      rotateIcon: false,
    };
    this.onClickNext = this.onClickNext.bind(this);
    this.showBadges = this.showBadges.bind(this);
    this.sendHsCodesToBackend = this.sendHsCodesToBackend.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeSpecs = this.onChangeSpecs.bind(this);
    this.onChangeTarget = this.onChangeTarget.bind(this);
    this.onChangeFileName = this.onChangeFileName.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.handleServicesImage = this.handleServicesImage.bind(this);
    this.addNewProduct = this.addNewProduct.bind(this);
    this.handlePdfUpload = this.handlePdfUpload.bind(this);
    this.onChangeHsCode = this.onChangeHsCode.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.onChangeWeight = this.onChangeWeight.bind(this);
    this.onChangeSeason = this.onChangeSeason.bind(this);
    this.onChangeDimension = this.onChangeDimension.bind(this);
    this.onChangeModeOfProduction = this.onChangeModeOfProduction.bind(this);
    this.onChangeCapacity = this.onChangeCapacity.bind(this);
    this.onChangeColor = this.onChangeColor.bind(this);
    this.onChangeMaxPrice = this.onChangeMaxPrice.bind(this);
    this.onChangeMinPrice = this.onChangeMinPrice.bind(this);
    this.onChangeIncoterm = this.onChangeIncoterm.bind(this);
    this.onChangeMinUnits = this.onChangeMinUnits.bind(this);
    this.onChangeCertification = this.onChangeCertification.bind(this);
    this.onChangeCertificationDescription =
      this.onChangeCertificationDescription.bind(this);
    this.onChangeSeasonalityDescription =
      this.onChangeSeasonalityDescription.bind(this);
  }

  onChangeSeasonalityDescription(e, type) {
    this.setState({ [type + "-seasonality"]: e });
  }

  onChangeCertificationDescription(e, type) {
    this.setState({ [type + "-certification"]: e });
  }

  async onClickNext() {
    const { searchingHsCode, offeringHsCode } = this.props;

    if (searchingHsCode && searchingHsCode.length) {
      for (let i = 0; i < searchingHsCode.length; i++) {
        let item = searchingHsCode[i];
        await this.addNewProduct("search", item.id);
      }
    }

    if (offeringHsCode && offeringHsCode.length) {
      for (let i = 0; i < offeringHsCode.length; i++) {
        let item = offeringHsCode[i];
        await this.addNewProduct("offer", item.id);
      }
    }

    this.props.onClickNext(4);
  }

  componentDidMount() {
    this.showBadges(true);
    this.showBadges(false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searchingHsCode !== prevProps.searchingHsCode) {
      this.showBadges(false);
    }
    if (this.props.offeringHsCode !== prevProps.offeringHsCode) {
      this.showBadges(true);
    }
  }

  onChangeName(item, val) {
    this.setState({ [item + "-name"]: val });
  }

  onChangeDescription(item, val) {

    this.setState({ [item + "-description"]: val });
  }

  onChangeTarget(item, val) {

    this.setState({ [item + "-target"]: val });
  }

  onChangeSpecs(item, val) {

    this.setState({ [item + "-specification"]: val });
  }

  onChangeFileName(item, val) {

    this.setState({ [item + "-fileName"]: val });
  }

  onChangeHsCode(item, val) {

    this.setState({ [item + "-hsCode"]: val });
  }

  onChangeQuantity(item, val) {

    this.setState({ [item + "-quantity"]: val });
  }

  onChangeWeight(item, val) {

    this.setState({ [item + "-weight"]: val });
  }

  onChangeMinUnits(item, val) {

    this.setState({ [item + "-minUnits"]: val });
  }

  onChangeCertification(item, val) {

    this.setState({ [item + "-certification"]: val });
  }

  onChangeSeason(item, val) {

    this.setState({ [item + "-season"]: val });
  }

  onChangeIncoterm(item, val) {
    let strVal = "";
    for (let i = 0; i < val.length; i++) {
      strVal += `${val[i].value}${i !== val.length - 1 ? "," : ""}`;
    }

    this.setState({ [item + "-incoterm"]: strVal });
  }

  onChangeDimension(item, val, key) {
    const dimension = this.state[item + "-dimension"];
    dimension[key] = val;
    this.setState({ [item + "-dimension"]: dimension });
  }

  onChangeModeOfProduction(item, val) {

    this.setState({ [item + "-modeOfProduction"]: val });
  }

  onChangeCapacity(item, val) {

    this.setState({ [item + "-capacity"]: val });
  }

  onChangeColor(item, val) {

    this.setState({ [item + "-color"]: val });
  }

  onChangeMaxPrice(item, val) {

    this.setState({ [item + "-maxPrice"]: val });
  }

  onChangeMinPrice(item, val) {

    this.setState({ [item + "-minPrice"]: val });
  }

  async onClickSubmit() {
    // for(let k = 0; k < this.state.servicesToShow.length; k++) {
    //     arr.push(this.state.servicesToShow[k].pns_id);
    // }
    // for(let i in this.state.valObj) {
    //     if(arr.includes(parseInt(i))) {
    //         this.state.valObj[i] ? await this.updateProduct(this.state.valObj[i], i) : void 0;
    //     } else {
    //         this.state.valObj[i] ? await this.addNewProduct(this.state.valObj[i]) : void 0;
    //     }
    // }
  }

  async handlePdfUpload(item, file) {
    let formData = new FormData();
    const { token } = this.props;
    formData.append("pdf", file);
    let pdf_id = await uploadPdf(formData, token);

    this.setState({ [item + "-pdf"]: pdf_id });
  }

  async handleServicesImage(item, file) {
    let formData = new FormData();
    const { token } = this.props;
    const { image } = this.state;
    formData.append("image", file);
    let image_id = await uploadImage(formData, token);
    image.push(image_id);

    this.setState({ [item + "-image"]: image });
  }

  onChangeType(item, type) {

    this.setState({ [item + "-type"]: type });
  }

  addNewProduct(type, hsCode) {
    return new Promise((resolve, reject) => {
      const { token, companyIds } = this.props;
      this.setState({ showConfirmationModal: false });
      if (!this.state[type + "-name"] && !this.state[type + "-description"]) {
        this.setState({ showDescriptionError: true, showTitleError: true });
        NotificationManager.error("Name and description is required");
        this.showBadges(true);
        this.showBadges(false);
        return false;
      } else if (!this.state[type + "-name"]) {
        this.setState({ showTitleError: true });
        NotificationManager.error("Name required");
        this.showBadges(true);
        this.showBadges(false);
        return false;
      } else if (!this.state[type + "-description"]) {
        NotificationManager.error("Description required");
        this.showBadges(true);
        this.showBadges(false);
        this.setState({ showDescriptionError: true });
        return false;
      }
      this.setState({ showDescriptionError: false, showTitleError: false });
      let params = new URLSearchParams();
      this.state[type + "-image"] && this.state[type + "-image"].length > 0
        ? params.append(
            "image_ids",
            JSON.stringify(this.state[type + "-image"])
          )
        : void 0;
      params.append("type", type);
      this.state[type + "-name"]
        ? params.append("name", this.state[type + "-name"])
        : void 0;
      this.state[type + "-description"]
        ? params.append("description", this.state[type + "-description"])
        : void 0;
      this.state[type + "-target"]
        ? params.append("target_group", this.state[type + "-target"])
        : void 0;
      this.state[type + "-specification"]
        ? params.append("specification", this.state[type + "-specification"])
        : void 0;
      this.state[type + "-pdf"]
        ? params.append("company_ps_pdf_id", this.state[type + "-pdf"])
        : void 0;
      this.state[type + "-fileName"]
        ? params.append("file_name", this.state[type + "-fileName"])
        : void 0;
      params.append("hs_codes_id", hsCode);
      this.state[type + "-color"]
        ? params.append("colour", this.state[type + "-color"])
        : void 0;
      this.state[type + "-quantity"]
        ? params.append("quantity", this.state[type + "-quantity"])
        : void 0;
      this.state[type + "-weight"]
        ? params.append("weight", this.state[type + "-weight"])
        : void 0;
      this.state[type + "-minUnits"]
        ? params.append("min_units", this.state[type + "-minUnits"])
        : void 0;
      this.state[type + "-minPrice"]
        ? params.append("min_price", this.state[type + "-minPrice"])
        : void 0;
      this.state[type + "-maxPrice"]
        ? params.append("max_price", this.state[type + "-maxPrice"])
        : void 0;
      this.state[type + "-capacity"]
        ? params.append("capacity", this.state[type + "-capacity"])
        : void 0;
      this.state[type + "-certification"]
        ? params.append(
            "certification",
            this.state[type + "-certification"] === "true"
          )
        : void 0;
      this.state[type + "-season"]
        ? params.append("seasonality", this.state[type + "-season"])
        : void 0;
      let dim =
        this.state[type + "-dimension"].height +
        "," +
        this.state[type + "-dimension"].width +
        "," +
        this.state[type + "-dimension"].height;
      this.state[type + "-dimension"].height
        ? params.append("dimensions", dim)
        : void 0;
      this.state[type + "-modeOfProduction"]
        ? params.append(
            "mode_of_production",
            this.state[type + "-modeOfProduction"]
          )
        : void 0;
      this.state[type + "-incoterm"]
        ? params.append("incoterm", this.state[type + "-incoterm"])
        : void 0;
      axios
        .request({
          method: "put",
          url: `${base_url}/company/pns/${companyIds[0]}`,
          data: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            NotificationManager.success("Product and Services Added");
            resolve(res);
          } else {
            NotificationManager.error("Something went wrong. Try again");
          }
        });
    });
  }

  showBadges(offering) {
    const { showDescriptionError, showTitleError, rotateIcon } = this.state;
    let mapItem = offering
      ? this.props.offeringHsCode
      : this.props.searchingHsCode;
    let arr = mapItem.map((item, i) => {
      return (
        <Accordion
          defaultActiveKey="0"
          key={i}
          className="col-lg-12 pr-2 mt-3 CompanyServicesModal"
        >
          <Card style={{ overflow: "visible" }}>
            <Card.Header>
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                onClick={() =>
                  this.setState({ rotateIcon: !rotateIcon }, () =>
                    this.showBadges(offering)
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faArrowCircleUp}
                  className={"toggle-icon " + (rotateIcon ? "rotate-icon" : "")}
                  size={"lg"}
                />
                <h4 key={i} className="ml-3">
                  <p
                    className="badge badge-secondary text-left"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {item.item}
                  </p>
                </h4>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p>
                  Here you have the possibility to show a range of products and
                  services in detail to your fellow network members. You can
                  upload pictures for each item to make it highly visible.
                </p>
                <NewHsCodeForm
                  showDescriptionError={showDescriptionError}
                  showTitleError={showTitleError}
                  typeAdding={offering ? "offer" : "search"}
                  isEditing={false}
                  itemToEdit={null}
                  onChangeName={this.onChangeName}
                  onChangeDescription={this.onChangeDescription}
                  onChangeTarget={this.onChangeTarget}
                  onChangeSpecs={this.onChangeSpecs}
                  onChangeFileName={this.onChangeFileName}
                  onChangeImage={this.handleServicesImage}
                  onChangePdf={this.handlePdfUpload}
                  onChangeType={this.onChangeType}
                  onChangeHsCode={this.onChangeHsCode}
                  onChangeQuantity={this.onChangeQuantity}
                  onChangeWeight={this.onChangeWeight}
                  onChangeMinUnits={this.onChangeMinUnits}
                  onChangeCertification={this.onChangeCertification}
                  onChangeSeason={this.onChangeSeason}
                  onChangeDimension={this.onChangeDimension}
                  onChangeModeOfProduction={this.onChangeModeOfProduction}
                  onChangeColor={this.onChangeColor}
                  onChangeCapacity={this.onChangeCapacity}
                  onChangeMinPrice={this.onChangeMinPrice}
                  onChangeMaxPrice={this.onChangeMaxPrice}
                  onChangeIncoterm={this.onChangeIncoterm}
                  onChangeCertificationDescription={
                    this.onChangeCertificationDescription
                  }
                  onChangeSeasonalityDescription={
                    this.onChangeSeasonalityDescription
                  }
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    });
    offering
      ? this.setState({ offeringVal: arr })
      : this.setState({ searchingVal: arr });
  }

  sendHsCodesToBackend(hsCode, type, target, specification) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append("hs_codes_id", hsCode);
      params.append("type", type);
      target ? params.append("target_group", target) : void 0;
      specification ? params.append("specification", specification) : void 0;

      axios
        .request({
          method: "put",
          url: `${base_url}/company/pns/${this.props.companyIds[0]}`,
          data: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${this.props.token}`,
          },
        })
        .then((res) => {
          resolve(res.data.status);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  render() {
    return (
      <div className="text-left position-relative step-wizard">
        <h4 className="font-weight-light">
          Please specify your search/offer to assist our network, to identify
          the perfect match for you.
        </h4>
        <h6 className="my-3">Offering</h6>
        <div className="row">
          {this.state.offeringVal.length > 0
            ? [this.state.offeringVal]
            : void 0}
        </div>
        <h6 className="my-3">Searching</h6>
        <div className="row">
          {this.state.searchingVal.length > 0
            ? [this.state.searchingVal]
            : void 0}
        </div>

        <input
          type="button"
          className="btn btn-light mt-5"
          value="Back"
          onClick={this.props.onClickPrevious.bind(null, 4)}
        />
        <input
          type="button"
          className="btn btn-dark mt-5 float-right"
          value="Confirm"
          onClick={() => this.setState({ showConfirmationModal: true })}
        />
        <JumpseatConfirmationModal
          show={this.state.showConfirmationModal}
          onHide={() => this.setState({ showConfirmationModal: false })}
          onSubmit={this.onClickNext}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let searchingHsCode = state.hsCodes.searching;
  let offeringHsCode = state.hsCodes.offering;
  let companyIds = state.login.company_ids;
  return {
    token,
    searchingHsCode,
    offeringHsCode,
    companyIds,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Step4);
