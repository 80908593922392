import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {store} from './../store/store'

function isJumpseatUser() {
    try {
        if((store.getState().login.level === 'dk-jumpseat' || store.getState().login.level === 'dk-accepted') && store.getState().login.isLoggedIn) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.log(e);
    }
}

const JumpseatRoutes = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isJumpseatUser() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={ store.getState().login.isLoggedIn ? { pathname: `/newsfeed`, state: { from: props.location }} :  { pathname: `/`, state: { from: props.location }}} />
                )
            }
        />
    )
};

export default JumpseatRoutes;
